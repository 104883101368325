import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-terms-conditions-modal',
    templateUrl: './terms-conditions-modal.component.html',
    styleUrls: ['./terms-conditions-modal.component.scss'],
})
export class TermsConditionsModalComponent implements OnInit {
    constructor(private modalCtrl: ModalController) {}

    ngOnInit() {
        //    mody
    }

    close(): void {
        this.modalCtrl.dismiss({
            dismissed: true,
        });
    }
}
