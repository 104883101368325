import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-billing-data-empty-modal',
    templateUrl: './billing-data-empty-modal.component.html',
    styleUrls: ['./billing-data-empty-modal.component.scss'],
})
export class BillingDataEmptyModalComponent {
    constructor(private modalCtrl: ModalController, private router: Router) {}

    public async closeModal(): Promise<void> {
        await this.router.navigateByUrl('/account');
        await this.modalCtrl
            .dismiss({
                dismissed: true,
            })
            .then();
    }
}
