import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Page } from 'src/app/classes/page';
import { Advertisement } from 'src/app/models/advertisement';
import { AppService } from 'src/app/services/app.service';
import { ModalService } from 'src/app/services/modal.service';

@Component({
    selector: 'app-warning-payment-modal',
    templateUrl: './warning-payment-modal.component.html',
    styleUrls: ['./warning-payment-modal.component.scss'],
})
export class WarningPaymentModalComponent {
    @Input() advertisement: Advertisement;
    constructor(
        private modalCtrl: ModalController,
        private modalSrv: ModalService,
    ) {}

    public async closeModal(): Promise<void> {
        await this.modalCtrl.dismiss();
    }

    public openModalPaymentOxxoMethod(): void {
        this.modalSrv.openModalPaymentOxxoMethod(this.advertisement).then();
        this.closeModal();
    }
}
