import { AfterViewChecked, Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ModalService } from '../../services/modal.service';
import { Advertisement } from '../../models/advertisement';
import { Builder } from 'builder-pattern';
import { PaymentIntent } from 'src/app/models/payment';
import {
    ProcessorEntity,
    ProcessorMethod,
    AuthPayPal,
} from '../../models/payment';
import { PaymentService } from '../../services/payment.service';
import { first } from 'rxjs/operators';
import { ToolsService } from '../../services/tools.service';
import { environment } from '../../../environments/environment';
import { AppService } from '../../services/app.service';
import { ErrorString } from '../../classes/messages';
import { AngularFireAnalytics } from '@angular/fire/analytics';

declare let paypal: any;
@Component({
    selector: 'app-payment-modal-component',
    templateUrl: './payment-modal.component.html',
    styleUrls: ['./payment-modal.component.scss'],
})
export class PaymentModalComponent implements AfterViewChecked {
    @Input() advertisement: Advertisement;
    paypalConfig: any = {
        env: environment.paypal.env,
        country: 'MX',
        commit: false,
        client: {
            sandbox: environment.paypal.clientId,
            production: environment.paypal.clientId,
        },
        style: {
            tagline: false,
            color: 'gold',
            size: 'large',
            shape: 'pill',
            label: 'paypal',
        },
        payment: async () => {
            const paymentID = await this.payWithPaypal();
            return paymentID;
        },
        onAuthorize: async (data) => {
            const paypalRequest = Builder(AuthPayPal)
                .paymentID(data.paymentID)
                .payerID(data.payerID)
                .build();
            const authRes = await this.paymentSrv
                .authPaymentPaypal(paypalRequest, this.advertisement.contextId)
                .toPromise();
            if (authRes.success) {
                this.closeModal();
                this.toolsSrv.goRoutePaymentSuccess();
            } else {
                this.appSrv.showToastDanger(ErrorString.unknownError).then();
            }
        },
    };

    addScript = false;
    constructor(
        private modalCtrl: ModalController,
        public modalSrv: ModalService,
        private paymentSrv: PaymentService,
        private toolsSrv: ToolsService,
        private appSrv: AppService,
        private analytics: AngularFireAnalytics
    ) {

        this.analytics.logEvent('click', { 
            'event_category': 'Button',
            'event_label': 'Pagar',
            'action': 'Pagar'
          });
    }

    async ngAfterViewChecked(): Promise<void> {
        if (!this.addScript) {
            await this.addPaypalScript();
            paypal.Button.render(this.paypalConfig, '#paypal-checkout-button');
        }
    }

    public openModalPaymentOxxoMethod(): void {
        this.modalSrv.openModalWarningOxxoPayment(this.advertisement).then();
        this.closeModal();
    }

    public openModalPaymentCardMethod(): void {
        this.modalSrv.openModalPaymentCardMethod(this.advertisement).then();
        this.closeModal();
    }

    public async payWithPaypal(): Promise<string> {
        const configPayment = Builder(PaymentIntent)
            .advertisementUUID(this.advertisement.uuid)
            .amount(this.advertisement.details.price.priceWithTax)
            .processorEntity(ProcessorEntity.paypal)
            .processorMethod(ProcessorMethod.default)
            .build();

        const res = await this.paymentSrv
            .paypalPayment(configPayment, this.advertisement.contextId)
            .pipe(first())
            .toPromise();
        return res.clientSecret;
    }

    addPaypalScript(): Promise<any> {
        this.addScript = true;
        return new Promise((resolve, reject) => {
            const scripttagElement = document.createElement('script');
            scripttagElement.src =
                'https://www.paypalobjects.com/api/checkout.js';
            scripttagElement.onload = resolve;
            document.body.appendChild(scripttagElement);
        });
    }

    public closeModal(): void {
        this.modalCtrl
            .dismiss({
                dismissed: true,
            })
            .then();
    }
}
