import { Component, EventEmitter, forwardRef, Input, OnInit, Output, HostListener } from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
} from '@angular/forms';
import { ErrorFormString } from '../../classes/messages';
import { String } from '../../classes/string';

@Component({
    selector: 'app-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => InputComponent),
            multi: true,
        },
    ],
})
export class InputComponent implements OnInit, ControlValueAccessor, Validator {
    @Input() label = '';
    @Input() type = 'text';
    @Input() placeholder = '';
    @Input() disabled: boolean;
    @Input() accept:string;
    @Input() fileName:string;

    @Output() showFile = new EventEmitter();

    messageError: string;
    isTypePassword: boolean;
    showPassword: boolean;
    value;
    touched: boolean;
    // eslint-disable-next-line
    onChange = (_) => {

    };
    // eslint-disable-next-line
    onTouch = () => {
    };
    // eslint-disable-next-line
    onValidatorChange = () => {
    };

    ngOnInit(): void {
        if (this.type === 'password') {
            this.isTypePassword = true; 
        }

        if (this.type === 'file'){
            this.value = this.fileName;
        }
    
    }

    @HostListener('change', ['$event.target.files']) handleChange(files: FileList | null): void {
        if (this.type === 'file'){
            const file = files && files.length ? files[0] : null;
            this.value = file;
            this.onChange(file);
            this.onTouch();
        }
    }

    onInput(value): void {
        this.value = value;
        this.touched = true;
        this.onTouch();
        this.onChange(this.value);
        this.onValidatorChange();
    }

    toggleType(): void {
        this.showPassword = !this.showPassword;
        this.type = this.showPassword ? 'text' : 'password';
    }

    toggleFile(): void {
        this.fileName = "";
    }

    writeValue(value): void {
        this.value = value || '';
    }

    // eslint-disable-next-line
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    // eslint-disable-next-line
    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if (control.touched && control.errors) {
            // tslint:disable-next-line: forin
            for (const key in control.errors) {
                if (key === 'minlength') {
                    const { requiredLength, actualLength } = control.getError(
                        key,
                    );
                    this.messageError = String.format(
                        ErrorFormString.minlength,
                        requiredLength,
                        actualLength,
                    );
                } else if (key === 'maxlength') {
                    const { requiredLength, actualLength } = control.getError(
                        key,
                    );
                    this.messageError = String.format(
                        ErrorFormString.maxlength,
                        requiredLength,
                        actualLength,
                    );
                } else {
                    this.messageError = ErrorFormString[key];
                }
                break;
            }
        } else {
            this.messageError = null;
        }

        return null;
    }

    registerOnValidatorChange(fn: () => void): void {
        this.onValidatorChange = fn;
    }

    sendShowFile() {
        this.showFile.emit();
    }
}
