import {
    Component,
    Input,
    Output,
    EventEmitter,
    forwardRef,
    AfterViewInit,
} from '@angular/core';
import { OptionSelect } from '../../interfaces/select';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ActionSheetController } from '@ionic/angular';

@Component({
    selector: 'app-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectComponent),
            multi: true,
        },
    ],
})
export class SelectComponent implements AfterViewInit, ControlValueAccessor {
    @Input() label: string;
    @Input() placeholder = 'Selecciona...';
    @Input() options: Array<OptionSelect> = [];
    @Input() optionDefault: OptionSelect;
    @Input() disabled: boolean;
    @Output() changeSelected = new EventEmitter<OptionSelect>();
    optionSelected: OptionSelect;
    // eslint-disable-next-line
    private onChange = (_: any) => {
    };
    // eslint-disable-next-line
    private onTouch = () => {
    };

    constructor(public actionSheetController: ActionSheetController) {}

    ngAfterViewInit(): void {
        if (this.optionDefault) {
            this.optionSelected = this.findOption(this.optionDefault);
            this.onChange(this.optionSelected);
        }
    }

    private findOption(option: OptionSelect): OptionSelect {
        return this.options.find((opt) => opt.id === option.id);
    }

    private selectOption(option: OptionSelect): void {
        this.optionSelected = option;
        this.changeSelected.emit(option);
        this.onChange(this.optionSelected);
    }

    setCurrentSelection(option: OptionSelect): void {
        this.selectOption(option);
        this.onTouch();
    }

    // eslint-disable-next-line
    async openActionSheet(): Promise<any> {
        const actionSheet = await this.actionSheetController.create({
            mode: 'md',
            cssClass: 'action-sheet-select',
            buttons: this.options.map((option) => ({
                text: option.label,
                handler: () => {
                    this.selectOption(option);
                    this.onTouch();
                },
            })),
        });
        await actionSheet.present();
    }

    writeValue(option: OptionSelect): void {
        if (option) {
            this.optionSelected = this.findOption(option);
        } else {
            this.optionSelected = null;
        }
        this.onChange(this.optionSelected);
    }

    // eslint-disable-next-line
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    // eslint-disable-next-line
    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
