import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AdvertisementRequestByFilter } from '../models/advertisement-search';
import { Builder } from 'builder-pattern';

@Injectable({
    providedIn: 'root',
})
export class ToolsService {
    constructor(private router: Router) {}

    public goRouteProfile(): void {
        this.router.navigate(['/profile']).then();
    }

    public goRouteHome(): void {
        this.router.navigate(['/home']).then();
    }

    public goRouteCreateAdvertisement(): void {
        window.location.href = '/create-advertisement';
        // this.router.navigate(['/create-advertisement']).then();
    }

    public goRouteEditAdvertisement(advertisementId: string): void {
        this.router.navigate(['/edit-advertisement', advertisementId]).then();
    }

    public goRouteAccount(): void {
        this.router.navigate(['/account']).then();
    }

    public goRouteMyAdvertisements(): void {
        this.router.navigate(['/my-advertisements']).then();
    }

    public goRouteAbout(): void {
        this.router.navigate(['/about']).then();
    }

    public goRouteSearchByFilter(
        filter: AdvertisementRequestByFilter = Builder(
            AdvertisementRequestByFilter,
        ).build(),
    ): void {
        this.router
            .navigate(['/search-by-filter'], {
                queryParams: {
                    category: filter.categoryUUID,
                    subcategory: filter.subcategoryUUID,
                    date: filter.date,
                    keyword: filter.keyWord,
                },
            })
            .then();
    }

    public goRoutePaymentSuccess(): void {
        this.router.navigate(['/payment-success']).then();
    }
}
