import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { PaymentIntent, AuthPayPal, PaypalResponse } from '../models/payment';
import { Builder } from 'builder-pattern';
import { map, retry } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class PaymentService {
    constructor(private http: HttpClient) {}

    private static convertPayloadToPaymentIntentModel(
        payload: any,
    ): PaymentIntent {
        return Builder(PaymentIntent)
            .uuid(payload.uuid)
            .advertisementUUID(payload.advertisementUUID)
            .clientSecret(payload.externalUUID)
            .amount(payload.amount)
            .processorEntity(payload.processorEntity)
            .processorMethod(payload.processorMethod)
            .stripePaymentMethodId(payload.stripePaymentMethodId)
            .build();
    }

    public getPaymentIntent(
        paymentConfig: PaymentIntent,
        context_id: string,
    ): Observable<PaymentIntent> {
        return this.http
            .post(
                `${environment.api.payment}/api/v1/payment/${context_id}`,
                paymentConfig,
            )
            .pipe(
                retry(1),
                map((payload) =>
                    PaymentService.convertPayloadToPaymentIntentModel(payload),
                ),
            );
    }

    public setPaymentMethod(
        paymentConfig: PaymentIntent, 
        context_id: string
    ): Observable<PaymentIntent> {
        return this.http
            .post(
                `${environment.api.payment}/api/v1/payment/add_card_type/${context_id}`,
                paymentConfig,
            )
            .pipe(
                retry(3),
                map((payload) =>
                    PaymentService.convertPayloadToPaymentIntentModel(payload),
                ),
            );
    }

    public paypalPayment(
        paymentConfig: PaymentIntent,
        context_id: string,
    ): Observable<PaymentIntent> {
        return this.http
            .post(
                `${environment.api.payment}/api/v1/paypal/payment/${context_id}`,
                paymentConfig,
            )
            .pipe(
                retry(3),
                map((payload) => {
                    return PaymentService.convertPayloadToPaymentIntentModel(
                        payload,
                    );
                }),
            );
    }

    public authPaymentPaypal(authRequest: AuthPayPal, context_id: string) {
        return this.http
            .post(
                `${environment.api.payment}/api/v1/paypal/execute/${context_id}`,
                authRequest,
            )
            .pipe(
                retry(3),
                map((payload: PaypalResponse) => {
                    return Builder(PaypalResponse)
                        .success(payload.success)
                        .build();
                }),
            );
    }

    public stripeAmountFormat(amount: number): number {
        const formatStripe = amount * 100;
        return formatStripe;
    }
}
