export class PaymentIntent {
    uuid: string;
    advertisementUUID: string;
    clientSecret: string;
    amount: number;
    processorEntity: ProcessorEntity;
    processorMethod: ProcessorMethod;
    stripePaymentMethodId: string;

    public toObjectRequest(): any {
        return {
            uuid: this.uuid,
            advertisementUUID: this.advertisementUUID,
            externalUUID: this.clientSecret,
            amount: this.amount,
            processorEntity: this.processorEntity,
            processorMethod: this.processorMethod,
            stripePaymentMethodId: this.stripePaymentMethodId,
        };
    }
}

export enum ProcessorEntity {
    stripe = 'STRIPE',
    paypal = 'PAYPAL',
    promotion = 'PROMOTION',
}

export enum ProcessorMethod {
    default = 'DEFAULT',
    card = 'CARD',
    oxxo = 'OXXO',
    apple = 'APPLE PAY',
    google = 'GOOGLE PAY',
}

export class AuthPayPal {
    paymentID: string;
    payerID: string;

    public toObjectRequest(): any {
        return {
            paymentID: this.paymentID,
            payerID: this.payerID,
        };
    }
}

export class PaypalResponse {
    success: boolean;
}
