import { Component, Input } from '@angular/core';
import { AppService } from '../../services/app.service';
import { ModalService } from '../../services/modal.service';
import { CategoryService } from '../../services/category.service';
import { AuthenticationService } from '../../services/authentication.service';
import { PopoverController } from '@ionic/angular';
import { UserActionsComponent } from '../../popover/user-actions/user-actions.component';
import { ToolsService } from '../../services/tools.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
    @Input() hiddenCategoryList: boolean;

    constructor(
        public appSrv: AppService,
        public modalSrv: ModalService,
        public categorySrv: CategoryService,
        public authSrv: AuthenticationService,
        public popoverController: PopoverController,
        public toolSrv: ToolsService,
    ) {}

    public async showPopoverUserActions(ev: any): Promise<void> {
        const popover = await this.popoverController.create({
            component: UserActionsComponent,
            cssClass: 'my-custom-class',
            event: ev,
            translucent: true,
            mode: 'ios',
        });
        return await popover.present();
    }
}
