import { CategoryService } from '../services/category.service';
import { OptionSelect } from '../interfaces/select';
import { TitleCasePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Category } from '../models/category';
import { Subcategory } from '../models/subcategory';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Page } from './page';
import { AppService } from '../services/app.service';
import { filter, first } from 'rxjs/operators';
import { ToolsService } from '../services/tools.service';
import { Builder } from 'builder-pattern';
import {
    AdvertisementPagination,
    AdvertisementRequestByFilter,
    AdvertisementResponseByFilter,
} from '../models/advertisement-search';
import { ErrorResponse } from '../models/error-response';
import { ErrorManagerService } from '../services/error-manager.service';
import { AdvertisementService } from '../services/advertisement.service';
import { Strings } from './messages';

export class ViewSearch extends Page {
    advertisementResponse: AdvertisementResponseByFilter;
    formSearch: FormGroup;
    inputSearch = '';
    categoryList: Array<OptionSelect | Category> = [];
    subcategoryList: Array<OptionSelect> = [];
    advertisementCategories: Array<Category> = [];

    constructor(
        public appSrv: AppService,
        public categorySrv: CategoryService,
        public titleCasePipe: TitleCasePipe,
        public fb: FormBuilder,
        public toolsSrv: ToolsService,
        public advertisementSrv: AdvertisementService,
    ) {
        super(appSrv);
        this.setupForm();
        this.loadContext().then();
        this.setupCategoryList().then();
    }

    private setupForm(): void {
        this.formSearch = this.fb.group({
            category: [''],
            subcategory: [''],
            date: [''],
            keyword: [''],
        });
    }

    private async setupCategoryList(): Promise<void> {
        this.categorySrv.categoryList
            .pipe(
                filter((categoryList) => !!categoryList),
                first(),
            )
            .subscribe((categoryList) => {
                this.categoryList = categoryList.map((category) => ({
                    ...category,
                    id: category.uuid,
                    label: this.titleCasePipe.transform(category.category),
                }));
            });
    }

    public async searchSubcategoryList(option: OptionSelect): Promise<void> {
        const subcategoryList = await this.categorySrv
            .getSubcategoryList(option.id)
            .toPromise();

        this.subcategoryList = subcategoryList.map((subcat) => ({
            ...subcat,
            id: subcat.uuid,
            label: this.titleCasePipe.transform(subcat.subcategory),
        }));
    }

    public clearFilter(): void {
        this.formSearch.reset();
        this.formSearch.get('category').setValue(null);
        this.formSearch.get('subcategory').setValue(null);
        this.formSearch.get('date').setValue(null);
    }

    public goSearchFilterPageByKeyWord(): void {
        this.toolsSrv.goRouteSearchByFilter(
            Builder(AdvertisementRequestByFilter)
                .keyWord(this.inputSearch)
                .build(),
        );
    }

    public goSearchFilterPageByForm(): void {
        const category: Category = this.formSearch.get('category').value;
        const subcategory: Subcategory = this.formSearch.get('subcategory')
            .value;
        const date: NgbDate = this.formSearch.get('date').value;
        const keyword: string = this.formSearch.get('keyword').value;

        this.toolsSrv.goRouteSearchByFilter(
            Builder(AdvertisementRequestByFilter)
                .categoryUUID(category ? category.uuid : '')
                .subcategoryUUID(subcategory ? subcategory.uuid : '')
                .date(date ? `${date.day}/${date.month}/${date.year}` : '')
                .keyWord(keyword || '')
                .build(),
        );
    }

    public async searchByWord(itemByPage = 9): Promise<void> {
        try {
            const request = Builder(AdvertisementRequestByFilter)
                .contextId(this.context.contextId)
                .keyWord(this.inputSearch)
                .searchPagination(
                    Builder(AdvertisementPagination)
                        .page(0)
                        .hitsPerPage(itemByPage)
                        .build(),
                )
                .build();

            this.advertisementResponse = await this.advertisementSrv
                .getAdvertisementListByFilter(request)
                .toPromise();
        } catch (e) {
            const error = e as ErrorResponse;
            this.appSrv
                .showToastDanger(
                    ErrorManagerService.getMessageErrorByTypeErrorResponse(
                        error.getFirstError(),
                    ),
                )
                .then();
        }
    }

    public async searchByFilter(itemByPage = 9): Promise<void> {
        try {
            const request = Builder(AdvertisementRequestByFilter)
                .contextId(this.context.contextId)
                .searchPagination(
                    Builder(AdvertisementPagination)
                        .page(0)
                        .hitsPerPage(itemByPage)
                        .build(),
                )
                .build();

            const category: Category = this.formSearch.get('category').value;
            if (category) {
                request.categoryUUID = category.uuid;
            }

            const subcategory: Subcategory = this.formSearch.get('subcategory')
                .value;
            if (subcategory) {
                request.subcategoryUUID = subcategory.uuid;
            }

            const date: NgbDate = this.formSearch.get('date').value;
            if (date) {
                request.date = `${date.day}/${date.month}/${date.year}`;
            }

            const keyword: string = this.formSearch.get('keyword').value;
            if (keyword) {
                request.keyWord = keyword;
            }

            this.advertisementResponse = await this.advertisementSrv
                .getAdvertisementListByFilter(request)
                .toPromise();
        } catch (e) {
            const error = e as ErrorResponse;
            this.appSrv
                .showToastDanger(
                    ErrorManagerService.getMessageErrorByTypeErrorResponse(
                        error.getFirstError(),
                    ),
                )
                .then();
        }
    }

    public async changePage(page: number): Promise<void> {
        await this.appSrv.showLoading();
        const request = Builder(AdvertisementRequestByFilter)
            .contextId(this.advertisementResponse.search.contextId)
            .searchPagination(
                Builder(AdvertisementPagination)
                    .page(page - 1)
                    .hitsPerPage(
                        this.advertisementResponse.search.searchPagination
                            .hitsPerPage,
                    )
                    .build(),
            )
            .build();

        if (this.advertisementResponse.search.categoryUUID) {
            request.categoryUUID = this.advertisementResponse.search.categoryUUID;
        }
        if (this.advertisementResponse.search.subcategoryUUID) {
            request.subcategoryUUID = this.advertisementResponse.search.subcategoryUUID;
        }
        if (this.advertisementResponse.search.date) {
            request.date = this.advertisementResponse.search.date;
        }
        if (this.advertisementResponse.search.keyWord) {
            request.keyWord = this.advertisementResponse.search.keyWord;
        }

        this.advertisementResponse = await this.advertisementSrv
            .getAdvertisementListByFilter(request)
            .toPromise();
   

        this.advertisementResponse.advertisementList = this.advertisementResponse.advertisementList.map(
            (adv) => {
                const categoryFound = this.categoryList.find(
                    (category) =>
                        adv.categoryUUID ===
                        (category as Category).uuid,
                );
                var categoryname = (categoryFound as Category).category;
                adv.categoryUUID = categoryname.replace(/\s/g, "-");
                return adv;
            },
        );   
        
        await this.appSrv.dismissLoading(); 
   
        
    }

    public loadCategoryList(): Promise<void> {
        return new Promise((resolve) => {
            const idInterval = setInterval(async () => {
                
                        this.advertisementCategories = await this.categorySrv.categoryList
                            .pipe(first())
                            .toPromise();

                if (this.advertisementCategories) {
                    clearInterval(idInterval);
                    resolve();
                }
            }, 50);
        });
    }
}
