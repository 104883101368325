import { Component } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { PopoverController } from '@ionic/angular';
import { ToolsService } from '../../services/tools.service';
import { AppService } from '../../services/app.service';
import { AngularFireAnalytics } from '@angular/fire/analytics';

@Component({
    selector: 'app-user-actions',
    templateUrl: './user-actions.component.html',
    styleUrls: ['./user-actions.component.scss'],
})
export class UserActionsComponent {
    constructor(
        private popoverCtrl: PopoverController,
        public authSrv: AuthenticationService,
        public toolsSrv: ToolsService,
        private appSrv: AppService,
        private analytics: AngularFireAnalytics
    ) {}

    public goRouteAccount(): void {
        this.toolsSrv.goRouteAccount();
        this.popoverCtrl.dismiss().then();
    }

    public goRouteCreateAdvertisement(): void {
        this.analytics.logEvent('click', { 
            'event_category': 'Button',
            'event_label': 'Crear anuncio',
            'action': 'Crear anuncio',
          });
        this.toolsSrv.goRouteCreateAdvertisement();
        this.popoverCtrl.dismiss().then();
    }

    public goRouteMyAdvertisements(): void {
        this.toolsSrv.goRouteMyAdvertisements();
        this.popoverCtrl.dismiss().then();
    }

    public async logoutSession(): Promise<void> {
        await this.appSrv.showLoading();
        await this.authSrv.logout();
        await this.appSrv.dismissLoading();
        this.popoverCtrl.dismiss().then();
        this.toolsSrv.goRouteHome();
    }
}
