import { Component, forwardRef, Input } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CalendarComponent),
            multi: true,
        },
    ],
})
export class CalendarComponent implements ControlValueAccessor {
    @Input() label: string;
    @Input() placeholder = '';
    @Input() disabled: boolean;
    dateSelected: NgbDate;
    // eslint-disable-next-line
    onChange = (_: NgbDate) => {
    };
    // eslint-disable-next-line
    onTouch = () => {
    };

    onDateSelection(date: NgbDate): void {
        this.dateSelected = date;
        this.onChange(date);
    }

    // eslint-disable-next-line
    writeValue(obj: any): void {
        if (obj) {
            this.dateSelected = obj;
        } else {
            this.dateSelected = null;
        }
    }

    // eslint-disable-next-line
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    // eslint-disable-next-line
    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
