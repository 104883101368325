import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import {
    AdvertisementPagination,
    AdvertisementRequestByFilter,
    AdvertisementResponseByFilter,
} from '../models/advertisement-search';
import {
    Advertisement,
    AdvertisementDate,
    AdvertisementDetail,
    AdvertisementImage,
    AdvertisementOrderImage,
    AdvertisementPrice,
    AdvertisementPublishedStatus,
    AdvertisementStatus,
} from '../models/advertisement';
import { map, retry } from 'rxjs/operators';
import { Builder } from 'builder-pattern';
import { AdvertisementContactInfo } from '../models/advertisement';

@Injectable({
    providedIn: 'root',
})
export class AdvertisementService {
    constructor(private http: HttpClient) {}

    public getAdvertisement(
        advertisementId: string,
    ): Observable<Advertisement> {
        return this.http
            .get(
                `${environment.api.advertisement}/api/v1/classified/${advertisementId}`,
                {},
            )
            .pipe(
                retry(3),
                map((response: any) =>
                    this.convertPayloadToAdvertisementModel(response),
                ),
            );
    }

    public getAdvertisementByOwner(
        advertisementId: string,
    ): Observable<Advertisement> {
        return this.http
            .get(
                `${environment.api.advertisement}/api/v1/classified/owner/${advertisementId}`,
                {},
            )
            .pipe(
                retry(3),
                map((response: any) =>
                    this.convertPayloadToAdvertisementModel(response),
                ),
            );
    }

    public getAdvertisementListByOwner(
        search: AdvertisementRequestByFilter,
    ): Observable<AdvertisementResponseByFilter> {
        return this.http
            .post(
                `${environment.api.advertisement}/api/v1/search/owner`,
                search,
            )
            .pipe(
                retry(3),
                map((response: any) => {
                    return Builder(AdvertisementResponseByFilter)
                        .advertisementList(
                            response.advertisements.map((advertisement) =>
                                this.convertPayloadToAdvertisementModel(
                                    advertisement,
                                ),
                            ),
                        )
                        .search(
                            Builder(AdvertisementRequestByFilter)
                                .contextId(response.search.contextId)
                                .keyWord(response.search.keyWord)
                                .categoryUUID(response.search.categoryUUID)
                                .subcategoryUUID(
                                    response.search.subcategoryUUID,
                                )
                                .date(response.search.date)
                                .searchPagination(
                                    Builder(AdvertisementPagination)
                                        .page(
                                            response.search.searchPagination
                                                .page,
                                        )
                                        .hitsPerPage(
                                            response.search.searchPagination
                                                .hitsPerPage,
                                        )
                                        .nbHits(
                                            response.search.searchPagination
                                                .nbHits,
                                        )
                                        .nbPages(
                                            response.search.searchPagination
                                                .nbPages,
                                        )
                                        .build(),
                                )
                                .build(),
                        )
                        .build();
                }),
            );
    }

    public getAdvertisementListByFilter(
        search: AdvertisementRequestByFilter,
    ): Observable<AdvertisementResponseByFilter> {
        return this.http
            .post(`${environment.api.advertisement}/api/v1/search`, search)
            .pipe(
                retry(3),
                map((response: any) => {
                    return Builder(AdvertisementResponseByFilter)
                        .advertisementList(
                            response.advertisements.map((advertisement) =>
                                this.convertPayloadToAdvertisementModel(
                                    advertisement,
                                ),
                            ),
                        )
                        .search(
                            Builder(AdvertisementRequestByFilter)
                                .contextId(response.search.contextId)
                                .keyWord(response.search.keyWord)
                                .categoryUUID(response.search.categoryUUID)
                                .subcategoryUUID(
                                    response.search.subcategoryUUID,
                                )
                                .date(response.search.date)
                                .searchPagination(
                                    Builder(AdvertisementPagination)
                                        .page(
                                            response.search.searchPagination
                                                .page,
                                        )
                                        .hitsPerPage(
                                            response.search.searchPagination
                                                .hitsPerPage,
                                        )
                                        .nbHits(
                                            response.search.searchPagination
                                                .nbHits,
                                        )
                                        .nbPages(
                                            response.search.searchPagination
                                                .nbPages,
                                        )
                                        .build(),
                                )
                                .build(),
                        )
                        .build();
                }),
            );
    }

    public saveAdvertisement(
        advertisement: Advertisement,
    ): Observable<Advertisement> {
        const newAdvertisement = advertisement.toObjectRequest();
        newAdvertisement.advertisementStatus.creationStatus =
            newAdvertisement.advertisementStatus.creationStatus === 'PAYMENT'
                ? 'FINISHED'
                : newAdvertisement.advertisementStatus.creationStatuS;
        return this.http
            .post(
                `${environment.api.advertisement}/api/v1/classified`,
                newAdvertisement,
            )
            .pipe(
                retry(3),
                map((response) =>
                    this.convertPayloadToAdvertisementModel(response),
                ),
            );
    }

    public saveImageAdvertisement(
        imageBase64: string,
    ): Observable<AdvertisementImage> {
        return this.http
            .post(`${environment.api.advertisement}/api/v1/image`, {
                image: imageBase64,
            })
            .pipe(
                retry(3),
                map((response: any) =>
                    Builder(AdvertisementImage)
                        .uuid(response.uuid)
                        .originalBucketPath(response.originalBucketPath)
                        .thumbBucketPath(response.thumbBucketPath)
                        .build(),
                ),
            );
    }

    public convertPayloadToAdvertisementModel(payload: any): Advertisement {
        return Builder(Advertisement)
            .uuid(payload.uuid)
            .contextId(payload.contextId)
            .title(payload.title)
            .description(payload.description)
            .images(
                payload.images.map((orderImage) =>
                    Builder(AdvertisementOrderImage)
                        .image(
                            Builder(AdvertisementImage)
                                .uuid(orderImage.image.uuid)
                                .originalBucketPath(
                                    orderImage.image.originalBucketPath,
                                )
                                .thumbBucketPath(
                                    orderImage.image.thumbBucketPath,
                                )
                                .build(),
                        )
                        .order(orderImage.order)
                        .isPrintable(orderImage.isPrintable)
                        .build(),
                ),
            )
            .categoryUUID(payload.categoryUUID)
            .subCategoryUUID(payload.subCategoryUUID)
            .details(
                Builder(AdvertisementDetail)
                    .fontUUID(payload.advertisementDetails.fontUUID)
                    .additionUUID(payload.advertisementDetails.additionUUID)
                    .packageUUID(payload.advertisementDetails.packageUUID)
                    .date(
                        Builder(AdvertisementDate)
                            .webDate(
                                payload.advertisementDetails.advertisementDate
                                    .webDate,
                            )
                            .printDate(
                                payload.advertisementDetails.advertisementDate
                                    .printDate,
                            )
                            .build(),
                    )
                    .price(
                        Builder(AdvertisementPrice)
                            .price(
                                payload.advertisementDetails.advertisementPrice
                                    .price,
                            )
                            .tax(
                                payload.advertisementDetails.advertisementPrice
                                    .tax,
                            )
                            .priceWithTax(
                                payload.advertisementDetails.advertisementPrice
                                    .priceWithTax,
                            )
                            .build(),
                    )
                    .invoiceRequired(
                        payload.advertisementDetails.invoiceRequired,
                    )
                    .contactInfo(
                        Builder(AdvertisementContactInfo)
                            .contactSource(
                                payload.advertisementDetails.contactInfo
                                    ?.contactSource,
                            )
                            .contactType(
                                payload.advertisementDetails.contactInfo
                                    ?.contactType,
                            )
                            .build(),
                    )
                    .build(),
            )
            .status(
                Builder(AdvertisementStatus)
                    .creation(payload.advertisementStatus.creationStatus)
                    .payment(payload.advertisementStatus.paymentStatus)
                    .user(payload.advertisementStatus.userStatus)
                    .published(payload.advertisementStatus.publishedStatus)
                    .build(),
            )
            .owner(payload.advertisementOwner)
            .build();
    }
}
