import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Category } from '../models/category';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, first, map, retry } from 'rxjs/operators';
import { Builder } from 'builder-pattern';
import { Subcategory } from '../models/subcategory';
import { AppService } from './app.service';

@Injectable({
    providedIn: 'root',
})
export class CategoryService {
    private categoryList$: BehaviorSubject<
        Array<Category>
    > = new BehaviorSubject<Array<Category>>(null);

    get categoryList(): Observable<Array<Category>> {
        return this.categoryList$.asObservable();
    }

    constructor(private http: HttpClient, private appSrv: AppService) {
        this.loadCategoryList().then();
    }

    public async loadCategoryList(): Promise<void> {
        const context = await this.appSrv.context
            .pipe(
                filter((context) => !!context),
                first(),
            )
            .toPromise();
        const categoryList = await this.getCategoryList(
            context.contextId,
        ).toPromise();
        this.setCategoryList(categoryList);
    }

    private setCategoryList(categoryList: Array<Category>): void {
        this.categoryList$.next(categoryList);
    }

    public getCategory(uidCategory: string): Observable<Category> {
        return this.http
            .get(`${environment.api.context}/api/v1/categories/${uidCategory}`)
            .pipe(
                retry(3),
                map((data: Category) =>
                    Builder(Category)
                        .uuid(data.uuid)
                        .category(data.category)
                        .image(data.image)
                        .build(),
                ),
            );
    }

    public getCategoryList(contextId: string): Observable<Array<Category>> {
        return this.http
            .get(
                `${environment.api.context}/api/v1/ctx/${contextId}/categories`,
            )
            .pipe(
                retry(3),
                map((data: Array<Category>) =>
                    data.map((category) =>
                        Builder(Category)
                            .uuid(category.uuid)
                            .category(category.category)
                            .image(category.image)
                            .build(),
                    ),
                ),
            );
    }

    public getSubcategory(
        uidCategory: string,
        uidSubcategory: string,
    ): Observable<Subcategory> {
        return this.http
            .get(
                `${environment.api.context}/api/v1/categories/${uidCategory}/subcategories/${uidSubcategory}`,
            )
            .pipe(
                retry(3),
                map((data: Subcategory) =>
                    Builder(Subcategory)
                        .uuid(data.uuid)
                        .categoryUUID(data.categoryUUID)
                        .subcategory(data.subcategory)
                        .build(),
                ),
            );
    }

    public getSubcategoryList(
        categoryId: string,
    ): Observable<Array<Subcategory>> {
        return this.http
            .get(
                `${environment.api.context}/api/v1/categories/${categoryId}/subcategories`,
            )
            .pipe(
                retry(3),
                map((data: Array<Subcategory>) =>
                    data.map((subcategory) =>
                        Builder(Subcategory)
                            .uuid(subcategory.uuid)
                            .categoryUUID(subcategory.categoryUUID)
                            .subcategory(subcategory.subcategory)
                            .build(),
                    ),
                ),
            );
    }
}
