import { Component, Input } from '@angular/core';
import { Advertisement } from '../../models/advertisement';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-preview-printed',
    templateUrl: './preview-paper-modal.component.html',
    styleUrls: ['./preview-paper-modal.component.scss'],
})
export class PreviewPaperModalComponent {
    @Input() advertisement: Advertisement;

    constructor(private modalCtrl: ModalController) {}

    public closeModal(): void {
        this.modalCtrl
            .dismiss({
                dismissed: true,
            })
            .then();
    }
}
