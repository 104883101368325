import { ContactInfo } from './context';
export class Advertisement {
    private _uuid: string;
    private _contextId: string;
    private _title: string;
    private _description: string;
    private _images: Array<AdvertisementOrderImage>;
    private _categoryUUID: string;
    private _subCategoryUUID: string;
    private _details: AdvertisementDetail;
    private _status: AdvertisementStatus;
    private _owner: string;

    get uuid(): string {
        return this._uuid;
    }

    set uuid(value: string) {
        this._uuid = value;
    }

    get contextId(): string {
        return this._contextId;
    }

    set contextId(value: string) {
        this._contextId = value;
    }

    get title(): string {
        return this._title;
    }

    set title(value: string) {
        this._title = value;
    }

    get description(): string {
        return this._description;
    }

    set description(value: string) {
        this._description = value;
    }

    get images(): Array<AdvertisementOrderImage> {
        return this._images;
    }

    set images(value: Array<AdvertisementOrderImage>) {
        this._images = value;
    }

    get categoryUUID(): string {
        return this._categoryUUID;
    }

    set categoryUUID(value: string) {
        this._categoryUUID = value;
    }

    get subCategoryUUID(): string {
        return this._subCategoryUUID;
    }

    set subCategoryUUID(value: string) {
        this._subCategoryUUID = value;
    }

    get details(): AdvertisementDetail {
        return this._details;
    }

    set details(value: AdvertisementDetail) {
        this._details = value;
    }

    get status(): AdvertisementStatus {
        return this._status;
    }

    set status(value: AdvertisementStatus) {
        this._status = value;
    }

    get owner(): string {
        return this._owner;
    }

    set owner(value: string) {
        this._owner = value;
    }

    public getPictureWeb(): Array<AdvertisementOrderImage> {
        return this.images.filter((image) => !image.isPrintable);
    }

    public getPicturePaper(): AdvertisementOrderImage {
        return this.images.find((image) => image.isPrintable);
    }

    public toObjectRequest(): any {
        return {
            uuid: this.uuid,
            contextId: this.contextId,
            title: this.title,
            description: this.description,
            images: this.images && this.images.map((v) => v.toObjectRequest()),
            categoryUUID: this.categoryUUID,
            subCategoryUUID: this.subCategoryUUID,
            advertisementDetails:
                this.details && this.details.toObjectRequest(),
            advertisementStatus: this.status && this.status.toObjectRequest(),
            owner: this.owner,
        };
    }
}

export class AdvertisementOrderImage {
    image: AdvertisementImage;
    order: number;
    isPrintable: boolean;

    public toObjectRequest(): any {
        return {
            image: this.image && this.image.toObjectRequest(),
            order: this.order,
            isPrintable: this.isPrintable,
        };
    }
}

export class AdvertisementImage {
    uuid: string;
    originalBucketPath: string;
    thumbBucketPath: string;

    public toObjectRequest(): any {
        return {
            uuid: this.uuid,
            originalBucketPath: this.originalBucketPath,
            thumbBucketPath: this.thumbBucketPath,
        };
    }
}

export class AdvertisementDetail {
    fontUUID: string;
    additionUUID: string;
    packageUUID: string;
    date: AdvertisementDate;
    price: AdvertisementPrice;
    invoiceRequired: boolean;
    contactInfo: AdvertisementContactInfo;
    public toObjectRequest(): any {
        return {
            fontUUID: this.fontUUID,
            additionUUID: this.additionUUID,
            packageUUID: this.packageUUID,
            advertisementDate: this.date && this.date.toObjectRequest(),
            pradvertisementPrice: this.price && this.price.toObjectRequest(),
            invoiceRequired: this.invoiceRequired,
            contactInfo: this.contactInfo,
        };
    }
}

export class AdvertisementDate {
    webDate: Array<string>;
    printDate: Array<string>;

    public toObjectRequest(): any {
        return {
            webDate: this.webDate,
            printDate: this.printDate,
        };
    }
}

export class AdvertisementPrice {
    price: number;
    tax: number;
    priceWithTax: number;

    public toObjectRequest(): any {
        return {
            price: this.price,
            tax: this.tax,
            priceWithTax: this.priceWithTax,
        };
    }
}

export class AdvertisementStatus {
    creation: AdvertisementCreationStatus;
    payment: AdvertisementPaymentStatus;
    user: AdvertisementUserStatus;
    published: AdvertisementPublishedStatus;

    public toObjectRequest(): any {
        return {
            creationStatus: this.creation,
            paymentStatus: this.payment,
            userStatus: this.user,
            publishedStatus: this.published,
        };
    }
}

export class AdvertisementContactInfo {
    contactType: AdvertisementContactType;
    contactSource: string;
}

export enum AdvertisementCreationStatus {
    step1 = 'STEP1',
    step2 = 'STEP2',
    step3 = 'STEP3',
    step4 = 'STEP4',
    finished = 'FINISHED',
    payment = 'PAYMENT',
}

export enum AdvertisementPaymentStatus {
    pending = 'PENDING',
    processing = 'PROCESSING',
    paid = 'PAID',
    rejected = 'REJECTED',
}

export enum AdvertisementUserStatus {
    active = 'ACTIVE',
    cancelled = 'CANCELLED',
    paused = 'PAUSED',
}

export enum AdvertisementPublishedStatus {
    published = 'PUBLISHED',
    expired = 'EXPIRED',
    notPublished = 'NOT PUBLISHED',
}

export enum AdvertisementContactType {
    phone = 'PHONENUMBER',
    email = 'EMAIL',
}
