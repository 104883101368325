export class Strings {
    static welcome = 'Bienvenido';
    static loadingApp = 'Cargando la aplicación...';
    static pleaseAwait = 'Por favor, espere...';
    static loadingConfig = 'Cargando la configuración';
    static loadingCategoryList = 'Cargando categorías';
    static unprocessableImage = 'Imagen no procesable';
    static packageNotSelected = 'Debes seleccionar un paquete';
    static updateSuccess = 'Actualizado correctamente';
    static unknownError =
        'Lo sentimos, un error ha ocurrido, inténtalo mas tarde.';

    static typeErrorForm = 'form';
    static typeErrorUnauthorized = 'unauthorized';
    static typeErrorNotFound = 'not_found';
    static typeErrorInvalidEmail = 'value_error.email';
    static typeErrorUnknown = 'unknown';
    static formError = 'Los datos enviados son incorrectos';
    static unauthorizedError = 'No tienes autorizacion para esta accion';
    static notFoundError = 'Lo que estas buscando no pudimos encontrarlo';
    static paymentSuccess = 'Pago registrado';
    static passwordInvalid =
        'La contraseña es inválida o el usuario no tiene contraseña';
    static alertTitle = 'Configuración';
    static alertMessage = 'Selecciona el periódico al que deseas entrar:';
}

export class ErrorString {
    static unknownError =
        'Lo sentimos, error ha ocurrido, inténtalo mas tarde.';
    static emailAlreadyInUse =
        'La dirección de correo electrónico ya está siendo utilizada por otra cuenta';
    static userNotFound =
        'No hay ningún registro de usuario que corresponda a este identificador';
    static urlRestorePasswordError =
        'La url esta mal formada, no será posible restaurar la contraseña';
    static categoryIdInvalid = 'La categoría seleccionada es invalida';
    static dailyNewsError = 'No se pudieron obtener las últimas noticias';
}

export class ErrorFormString {
    static required = 'Campo requerido';
    static email = 'El correo electrónico es invalido';
    static minlength = '{0} caracteres son requeridos, ahora tienes {1}';
    static matchpassword =
        'La contraseña y la confirmación del password no coinciden';
    static maxlength =
        'El máximo de carácteres requerido es {0}, ahora tienes {1}';
    static inValidSize= 'El tamaño máximo del archivo es 1Mb';
}
