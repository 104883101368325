import { Component, Input, OnInit } from '@angular/core';
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    Validators,
} from '@angular/forms';
import {
    PaymentIntent,
    ProcessorEntity,
    ProcessorMethod,
} from '../../models/payment';
import { AppService } from '../../services/app.service';
import { ModalController } from '@ionic/angular';
import { ModalService } from '../../services/modal.service';
import { StripeService } from 'ngx-stripe';
import { PaymentService } from '../../services/payment.service';
import { loadStripe } from '@stripe/stripe-js';
import { environment } from '../../../environments/environment';
import { Advertisement } from '../../models/advertisement';
import { Builder } from 'builder-pattern';
import { ErrorManagerService } from '../../services/error-manager.service';
import { ToolsService } from '../../services/tools.service';

@Component({
    selector: 'app-payment-oxxo-modal',
    templateUrl: './payment-oxxo-modal.component.html',
    styleUrls: ['./payment-oxxo-modal.component.scss'],
})
export class PaymentOxxoModalComponent implements OnInit {
    @Input() advertisement: Advertisement;
    stripe;
    stripeFormOxxo: FormGroup;
    stripePaymentIntentOxxo: PaymentIntent;

    constructor(
        private appSrv: AppService,
        private modalCtrl: ModalController,
        private modalSrv: ModalService,
        private fb: FormBuilder,
        private stripeSrv: StripeService,
        private paymentSrv: PaymentService,
        private errorManagerSrv: ErrorManagerService,
        private toolsSrv: ToolsService,
    ) {}

    async ngOnInit(): Promise<void> {
        this.setupForm();
        var key = null;
        if (environment.stripeKey[this.advertisement.contextId]) {
            key = environment.stripeKey[this.advertisement.contextId];
        } else {
            key = environment.stripeKey['0000'];
        }
        this.stripe = await loadStripe(key, {
            locale: 'es',
        });
        this.loadPaymentIntentOxxo().then();
    }

    private setupForm(): void {
        this.stripeFormOxxo = this.fb.group(
            {
                name: ['', [Validators.required]],
                lastname: ['', [Validators.required]],
                email: [
                    '',
                    [
                        Validators.required,
                        Validators.email,
                        Validators.pattern(
                            '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$',
                        ),
                    ],
                ],
            },
            { validators: this.validatePatternEmail },
        );
    }

    private validatePatternEmail(abstractControl: AbstractControl): void {
        const errorEmailPattern = abstractControl.get('email').errors;
        if (errorEmailPattern && errorEmailPattern.pattern) {
            abstractControl.get('email').setErrors({
                email: true,
            });
        }
    }

    private async loadPaymentIntentOxxo() {
        await this.errorManagerSrv.errorManager(async () => {
            const configPayment = Builder(PaymentIntent)
                .advertisementUUID(this.advertisement.uuid)
                .amount(this.advertisement.details.price.priceWithTax)
                .processorEntity(ProcessorEntity.stripe)
                .processorMethod(ProcessorMethod.oxxo)
                .build();
            this.stripePaymentIntentOxxo = await this.paymentSrv
                .getPaymentIntent(configPayment, this.advertisement.contextId)
                .toPromise();
        });
    }

    public async payWithOxxo(): Promise<void> {
        await this.errorManagerSrv.errorManager(async () => {
            const name =
                this.stripeFormOxxo.get('name').value +
                ' ' +
                this.stripeFormOxxo.get('lastname').value;
            const email = this.stripeFormOxxo.get('email').value;

            const result = await this.stripe.confirmOxxoPayment(
                this.stripePaymentIntentOxxo.clientSecret,
                {
                    payment_method: {
                        billing_details: {
                            name: name,
                            email: email,
                        },
                    },
                },
            );

            if (result.error) {
                this.appSrv.showToastDanger(result.error.message).then();
            } else {
                this.toolsSrv.goRoutePaymentSuccess();
                this.closeModal();
            }
        });
    }

    public closeModal(): void {
        this.modalCtrl
            .dismiss({
                dismissed: true,
            })
            .then();
    }
}
