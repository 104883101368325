import { Directive, Input } from '@angular/core';

@Directive({
    selector: 'img[appDefaultImage]',
    host: {
        '(error)': 'updateUrl()',
        '[src]': 'src',
    },
})
export class DefaultImageDirective {
    @Input() src: string;
    @Input() appDefaultImage: string;

    updateUrl(): void {
        this.src = this.appDefaultImage;
    }
}
