import { Advertisement } from './advertisement';

export class AdvertisementResponseByFilter {
    advertisementList: Array<Advertisement>;
    search: AdvertisementRequestByFilter;
}

export class AdvertisementRequestByFilter {
    contextId: string;
    keyWord: string;
    categoryUUID: string;
    subcategoryUUID: string;
    date: string;
    owner: string;
    searchPagination: AdvertisementPagination;
}

export class AdvertisementPagination {
    page: number;
    hitsPerPage: number;
    nbHits: number;
    nbPages: number;
}
