import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase';
import { first } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../models/user';
import { Builder } from 'builder-pattern';
import { LoadingController } from '@ionic/angular';
import { Strings } from '../classes/messages';
import { AppService } from './app.service';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    private user$: BehaviorSubject<User> = new BehaviorSubject<User>(null);

    get user(): Observable<User> {
        return this.user$.asObservable();
    }

    constructor(
        private afAuth: AngularFireAuth,
        private loadingCtrl: LoadingController,
    ) {
        this.listenUserSession();
    }

    public async factoryLoadUserSession(): Promise<void> {
        const loadingRef = await this.loadingCtrl.create({
            cssClass: 'app-loading',
            message: Strings.loadingApp,
            showBackdrop: true,
            spinner: 'bubbles',
            duration: 0,
        });
        await loadingRef.present();
        const userSession = await this.getUserSession()
            .pipe(first())
            .toPromise();
        await this.setUserSession(userSession);
        await loadingRef.dismiss();
    }

    private listenUserSession(): void {
        this.getUserSession().subscribe(this.setUserSession);
    }

    private getUserSession(): Observable<firebase.User> {
        return this.afAuth.user;
    }

    private setUserSession = async (user: firebase.User) => {
        if (user) {
            const {
                firstName,
                lastName,
            } = AuthenticationService.getFirstAndLastName(user.displayName);

            this.user$.next(
                Builder(User)
                    .id(user.uid)
                    .token(await user.getIdToken())
                    .fullName(user.displayName)
                    .firstName(firstName)
                    .lastName(lastName)
                    .picture(user.photoURL)
                    .email(user.email)
                    .emailVerified(user.emailVerified)
                    .provider(
                        user.providerData.map(
                            (provider) => provider.providerId,
                        ),
                    )
                    .build(),
            );
        } else {
            this.user$.next(null);
        }
    };

    private static getFirstAndLastName(
        fullname: string,
    ): { firstName: string; lastName: string } {
        let firstName: string;
        let lastName: string;

        const nameList = fullname ? fullname.split(' ') : [];

        if (nameList.length === 0) {
            firstName = '';
            lastName = '';
        } else if (nameList.length === 1) {
            firstName = nameList[0];
            lastName = '';
        } else if (nameList.length === 2) {
            firstName = nameList[0];
            lastName = nameList[1];
        } else if (nameList.length === 3) {
            firstName = nameList[0];
            lastName = nameList.slice(1, nameList.length).join(' ');
        } else if (nameList.length === 4) {
            firstName = nameList.slice(0, 2).join(' ');
            lastName = nameList.slice(2, nameList.length).join(' ');
        } else {
            firstName = nameList.slice(0, nameList.length - 2).join(' ');
            lastName = nameList
                .slice(nameList.length - 2, nameList.length)
                .join(' ');
        }

        return {
            firstName,
            lastName,
        };
    }

    public signInWithGoogle(): Promise<auth.UserCredential> {
        return this.afAuth.signInWithPopup(new auth.GoogleAuthProvider());
    }

    public signUpWithEmailAndPassword(
        email: string,
        password: string,
    ): Promise<auth.UserCredential> {
        return this.afAuth.createUserWithEmailAndPassword(email, password);
    }

    public signInWithEmailAndPassword(
        email: string,
        password: string,
    ): Promise<auth.UserCredential> {
        return this.afAuth.signInWithEmailAndPassword(email, password);
    }

    public confirmPasswordReset(code: string, email: string): Promise<void> {
        return this.afAuth.confirmPasswordReset(code, email);
    }

    public sendPasswordResetEmail(email: string): Promise<void> {
        return this.afAuth.sendPasswordResetEmail(email);
    }

    public async logout(): Promise<void> {
        await this.afAuth.signOut();
    }
}

export function factoryUserSession(
    authSrv: AuthenticationService,
    appSrv: AppService,
) {
    return async () => {
        await authSrv.factoryLoadUserSession();
        await appSrv.loadContext();
    };
}
