import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
    selector: 'app-image-cropper-modal',
    templateUrl: './image-cropper-modal.component.html',
    styleUrls: ['./image-cropper-modal.component.scss'],
})
export class ImageCropperModalComponent {
    @Input() image: string;
    newImage: string;
    constructor(private modalCtrl: ModalController) {}

    imageCropped(event: ImageCroppedEvent): void {
        this.newImage = event.base64;
    }

    public async closeModal(): Promise<any> {
        await this.modalCtrl.dismiss();
    }

    public async addImage(): Promise<any> {
        await this.modalCtrl.dismiss({
            imageCropped: this.newImage,
        });
    }
}
