import { Injectable } from '@angular/core';
import { RegisterModalComponent } from '../modals/register-modal/register-modal.component';
import { LoginModalComponent } from '../modals/login-modal/login-modal.component';
import { ModalController } from '@ionic/angular';
import { PrivacyNoticeModalComponent } from '../modals/privacy-notice-modal/privacy-notice-modal.component';
import { TermsConditionsModalComponent } from '../modals/terms-conditions-modal/terms-conditions-modal.component';
import { SearchModalComponent } from '../modals/search-modal/search-modal.component';
import { ForgotPasswordModalComponent } from '../modals/forgot-password-modal/forgot-password-modal.component';
import { SuccessModalComponent } from '../modals/success-modal/success-modal.component';
import { Advertisement } from '../models/advertisement';
import { PreviewWebModalComponent } from '../modals/preview-web-modal/preview-web-modal.component';
import { PreviewPaperModalComponent } from '../modals/preview-paper-modal/preview-paper-modal.component';
import { PaymentModalComponent } from '../modals/payment-modal/payment-modal.component';
import { BillingDataEmptyModalComponent } from '../modals/billing-data-empty-modal/billing-data-empty-modal.component';
import { PaymentCardModalComponent } from '../modals/payment-card-modal/payment-card-modal.component';
import { PaymentOxxoModalComponent } from '../modals/payment-oxxo-modal/payment-oxxo-modal.component';
import { DeleteAdvertisementComponent } from '../modals/delete-advertisement/delete-advertisement.component';
import { ImageCropperModalComponent } from '../modals/image-cropper-modal/image-cropper-modal.component';
import { WarningPaymentModalComponent } from '../modals/warning-payment-modal/warning-payment-modal.component';
import { AlertContactEmptyModalComponent } from '../modals/alert-contact-empty-modal/alert-contact-empty-modal.component';

@Injectable({
    providedIn: 'root',
})
export class ModalService {
    constructor(public modalCtrl: ModalController) {}

    public async openModalRegister(): Promise<void> {
        const modal = await this.modalCtrl.create({
            component: RegisterModalComponent,
            cssClass: 'app-modal tab-modal',
        });
        return await modal.present();
    }

    public async openModalLogin(): Promise<void> {
        const modal = await this.modalCtrl.create({
            component: LoginModalComponent,
            cssClass: 'app-modal tab-modal',
        });
        return await modal.present();
    }

    public async openModalPrivacyNotice(): Promise<void> {
        const modal = await this.modalCtrl.create({
            component: PrivacyNoticeModalComponent,
            cssClass: 'app-modal tab-modal',
        });
        return await modal.present();
    }

    public async openModalTermsConditions(): Promise<void> {
        const modal = await this.modalCtrl.create({
            component: TermsConditionsModalComponent,
            cssClass: 'app-modal tab-modal',
        });
        return await modal.present();
    }

    public async openModalSearch(): Promise<void> {
        const modal = await this.modalCtrl.create({
            component: SearchModalComponent,
            cssClass: 'app-modal',
        });
        return await modal.present();
    }

    public async openModalForgotPassword(): Promise<void> {
        const modal = await this.modalCtrl.create({
            component: ForgotPasswordModalComponent,
            cssClass: 'app-modal',
        });
        return await modal.present();
    }

    public async openModalSuccessRestorePassword(): Promise<void> {
        const modal = await this.modalCtrl.create({
            component: SuccessModalComponent,
            cssClass: 'app-modal tab-modal',
        });
        return await modal.present();
    }

    public async openModalPreviewWebAdvertisement(
        advertisement: Advertisement,
    ): Promise<void> {
        const modal = await this.modalCtrl.create({
            component: PreviewWebModalComponent,
            cssClass: 'app-modal fullscreen-desktop',
            componentProps: {
                advertisement: advertisement,
            },
        });
        return await modal.present();
    }

    public async openModalPreviewPaperAdvertisement(
        advertisement: Advertisement,
    ): Promise<void> {
        const modal = await this.modalCtrl.create({
            component: PreviewPaperModalComponent,
            cssClass: 'app-modal fullscreen-desktop',
            componentProps: {
                advertisement: advertisement,
            },
        });
        return await modal.present();
    }

    public async openModalPaymentMethod(
        advertisement: Advertisement,
    ): Promise<void> {
        const modal = await this.modalCtrl.create({
            component: PaymentModalComponent,
            cssClass: 'app-modal',
            componentProps: {
                advertisement: advertisement,
            },
        });
        return await modal.present();
    }

    public async openModalPaymentCardMethod(
        advertisement: Advertisement,
    ): Promise<void> {
        const modal = await this.modalCtrl.create({
            component: PaymentCardModalComponent,
            cssClass: 'app-modal',
            componentProps: {
                advertisement: advertisement,
            },
        });
        return await modal.present();
    }

    public async openModalPaymentOxxoMethod(
        advertisement: Advertisement,
    ): Promise<void> {
        const modal = await this.modalCtrl.create({
            component: PaymentOxxoModalComponent,
            cssClass: 'app-modal',
            componentProps: {
                advertisement: advertisement,
            },
        });
        return await modal.present();
    }

    public async openModalBillingDataEmpty(): Promise<void> {
        const modal = await this.modalCtrl.create({
            component: BillingDataEmptyModalComponent,
            cssClass: 'app-modal tab-modal',
        });
        return await modal.present();
    }

    public openModalDeleteAdvertisement(
        advertisement: Advertisement,
    ): Promise<HTMLIonModalElement> {
        return this.modalCtrl.create({
            component: DeleteAdvertisementComponent,
            cssClass: 'app-modal tab-modal',
            componentProps: {
                advertisement: advertisement,
            },
        });
    }

    public openModalImageCropper(image: string): Promise<HTMLIonModalElement> {
        return this.modalCtrl.create({
            component: ImageCropperModalComponent,
            cssClass: 'app-modal tab-modal',
            componentProps: {
                image,
            },
        });
    }

    public async openModalWarningOxxoPayment(
        advertisement: Advertisement,
    ): Promise<void> {
        const modal = await this.modalCtrl.create({
            component: WarningPaymentModalComponent,
            cssClass: 'app-modal tab-modal',
            componentProps: {
                advertisement: advertisement,
            },
        });
        return await modal.present();
    }

    public async openModalEmptyContactAlert(
        fn: any
    ): Promise<any> {
        const modal = await this.modalCtrl.create({
            component: AlertContactEmptyModalComponent,
            cssClass: 'app-modal tab-modal alert-msg',
            componentProps: {
                buttonFunction: fn,
            },
            id: 'alert'
        });
        await modal.present();
        return (await modal.onWillDismiss()).data;
    }

    
    async closeModalEmptyContactAlert(): Promise<void> {
        await  this.modalCtrl.dismiss(true, null, 'alert');
    }
}
