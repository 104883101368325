import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import {
    ContactInfo,
    Context,
    NewsPaperSkin,
    PaymentMethod,
    SocialNetwork,
} from '../models/context';
import {
    AlertController,
    LoadingController,
    ToastController,
    Platform,
} from '@ionic/angular';
import { Builder } from 'builder-pattern';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { first, map, retry } from 'rxjs/operators';
import { CategoryService } from './category.service';
import { Strings } from '../classes/messages';
import { Plugins } from '@capacitor/core';
import { ContextPreview } from '../models/context';

const { Storage } = Plugins;

@Injectable({
    providedIn: 'root',
})
export class AppService {
    public contextLoader: Context;
    private loadingRef: HTMLIonLoadingElement;
    private isLoading$: boolean;
    private context$: BehaviorSubject<Context> = new BehaviorSubject<Context>(
        null,
    );

    get context(): Observable<Context> {
        return this.context$.asObservable();
    }

    get isLoading(): boolean {
        return this.isLoading$;
    }

    constructor(
        private afAuth: AngularFireAuth,
        private toastCtrl: ToastController,
        private http: HttpClient,
        private loadingCtrl: LoadingController,
        private alertCtrl: AlertController,
        private platform: Platform,
    ) {}
    private static getHostname(): string {
        if (environment.production) {
            return window.location.hostname;
        } else {
            return 'clasificados-3672d.web.app';
        }
    }

    public async loadContext(): Promise<void> {
        const isMobile = this.platform.is('hybrid');
        const hostname = isMobile
            ? await this.mobileConfiguration()
            : AppService.getHostname();
        await this.showLoading(Strings.loadingConfig);
        const context = await this.getContext(hostname)
            .pipe(first())
            .toPromise();
        if (context) {
            this.setColorTheme(context.newspaperSkin);
            this.setContext(context);
        }
        await this.dismissLoading();
    }

    private setColorTheme(theme: NewsPaperSkin): void {
        if (theme && theme.primaryColor) {
            this.setColor('--ion-color-primary', theme.primaryColor);
        }

        if (theme && theme.primaryColor) {
            this.setColor('--ion-color-secondary', theme.secondaryColor);
        }
    }

    private setColor(colorName: string, colorHexadecimal: string): void {
        const el: any = document.querySelector('body');
        el.style.setProperty(colorName, colorHexadecimal);
    }

    private getContext(url: string): Observable<Context> {
        const hostname = url;
        return this.http
            .get(`${environment.api.context}/api/v1/ctx/${hostname}`)
            .pipe(
                retry(3),
                first(),
                map((data: Context) =>
                    Builder(Context)
                        .uuid(data.uuid)
                        .newspaperSkin(
                            Builder(NewsPaperSkin)
                                .displayName(data.newspaperSkin.displayName)
                                .primaryColor(data.newspaperSkin.primaryColor)
                                .secondaryColor(
                                    data.newspaperSkin.secondaryColor,
                                )
                                .logo(data.newspaperSkin.logo)
                                .build(),
                        )
                        .zipCodes(data.zipCodes.map((zipcode) => zipcode))
                        .paymentMethods(
                            data.paymentMethods.map((payments) =>
                                Builder(PaymentMethod)
                                    .uuid(payments.uuid)
                                    .name(payments.name)
                                    .url(payments.url)
                                    .build(),
                            ),
                        )
                        .newspaperRss(data.newspaperRss)
                        .socialNetworks(
                            data.socialNetworks.map((social) =>
                                Builder(SocialNetwork)
                                    .name(social.name)
                                    .icon(social.icon)
                                    .url(social.url)
                                    .build(),
                            ),
                        )
                        .contactInfo(
                            Builder(ContactInfo)
                                .contactNumber(data.contactInfo.contactNumber)
                                .extNumber(data.contactInfo.extNumber)
                                .contactEmail(data.contactInfo.contactEmail)
                                .build(),
                        )
                        .uri(data.uri)
                        .contextId(data.contextId)
                        .isPaper(data.isPaper)
                        .isWeb(data.isWeb)
                        .isSelling(data.isSelling)
                        .build(),
                ),
            );
    }

    private getContextList(): Observable<Array<ContextPreview>> {
        return this.http
            .get(`${environment.api.context}/api/v1/ctx/catalogue/`)
            .pipe(
                retry(3),
                first(),
                map((list: Array<ContextPreview>) =>
                    list.map((data: ContextPreview) =>
                        Builder(ContextPreview)
                            .displayName(data.displayName)
                            .uri(data.uri)
                            .uuid(data.uuid)
                            .build(),
                    ),
                ),
            );
    }
    private setContext(context: Context): void {
        this.contextLoader = context;
        this.context$.next(context);
    }

    public async showToastDanger(
        message: string,
        duration = 5000,
    ): Promise<void> {
        const toast = await this.toastCtrl.create({
            message,
            color: 'danger',
            duration,
        });
        toast.present().then();
    }

    public async showToastDefault(
        message: string,
        duration = 5000,
    ): Promise<void> {
        const toast = await this.toastCtrl.create({
            message,
            duration,
        });
        toast.present().then();
    }

    public async showLoading(message = Strings.pleaseAwait): Promise<void> {
        if (!this.isLoading$) {
            this.isLoading$ = true;
            this.loadingRef = await this.loadingCtrl.create({
                cssClass: 'app-loading',
                message,
                showBackdrop: true,
                spinner: 'bubbles',
                duration: 0,
            });
            await this.loadingRef.present();
        }
    }

    public async dismissLoading(): Promise<void> {
        if (this.isLoading$) {
            await this.loadingRef.dismiss();
            this.loadingRef = null;
            this.isLoading$ = false;
        }
    }

    public async showAlert(contextPrev: string = null): Promise<string> {
        const pappers: any = (
            await this.getContextList().pipe(first()).toPromise()
        ).map((context, index) => {
            return {
                type: 'radio',
                label: context.displayName,
                value: context.uri,
                checked: (index === 0 && !contextPrev) || context.uri === contextPrev ? true : false,
            };
        });
        let response: string;
        const alert = await this.alertCtrl.create({
            header: Strings.alertTitle,
            backdropDismiss: false,
            message: Strings.alertMessage,
            inputs: pappers,
            buttons: [
                {
                    text: 'Continuar',
                    handler: (data) => {
                        return data;
                    },
                },
            ],
        });

        await alert.present();
        await alert.onDidDismiss().then((data) => {
            response = data.data.values;
        });
        return response;
    }

    private async mobileConfiguration(): Promise<string> {
        let host = JSON.parse(
            await (await Storage.get({ key: 'papper' })).value,
        );
        if (host) {
            return host;
        } else {
            host = await this.showAlert();
            await Storage.set({
                key: 'papper',
                value: JSON.stringify(host),
            });
            return host;
        }
    }

    public async removeContext(): Promise<void> {
        const contextPrev = JSON.parse(
            await (await Storage.get({ key: 'papper' })).value,
        );
        const newContext = await this.showAlert(contextPrev);
        await this.showLoading(Strings.loadingConfig);
        await Storage.remove({key: 'papper'});
        await Storage.set({
            key: 'papper',
            value: JSON.stringify(newContext),
        });
        const context = await this.getContext(newContext)
            .pipe(first())
            .toPromise();
        if (context) {
            this.setColorTheme(context.newspaperSkin);
            this.setContext(context);
        }
        await this.dismissLoading();
    }

    async getContextMobile(): Promise<string> {
        const context = JSON.parse(
            await (await Storage.get({ key: 'papper' })).value,
        );

        return context;
    }
}
