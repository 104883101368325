import { NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { ButtonComponent } from '../../components/button/button.component';
import { SelectComponent } from '../../components/select/select.component';
import {
    NgbDatepickerModule,
    NgbDropdownModule,
} from '@ng-bootstrap/ng-bootstrap';
import { InputComponent } from '../../components/input/input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CalendarComponent } from '../../components/calendar/calendar.component';
import { SearchModalComponent } from '../../modals/search-modal/search-modal.component';
import { RegisterModalComponent } from '../../modals/register-modal/register-modal.component';
import { TermsConditionsModalComponent } from '../../modals/terms-conditions-modal/terms-conditions-modal.component';
import { PrivacyNoticeModalComponent } from '../../modals/privacy-notice-modal/privacy-notice-modal.component';
import { LoginModalComponent } from '../../modals/login-modal/login-modal.component';
import { ForgotPasswordModalComponent } from '../../modals/forgot-password-modal/forgot-password-modal.component';
import { SuccessModalComponent } from '../../modals/success-modal/success-modal.component';
import { IonicModule } from '@ionic/angular';
import { SafeUrlPipe } from '../../pipe/safe-url.pipe';
import { DefaultImageDirective } from '../../directives/default-image.directive';
import { HeaderComponent } from '../../components/header/header.component';
import { FooterComponent } from '../../components/footer/footer.component';
import { RouterModule } from '@angular/router';
import { LoadingComponent } from '../../components/loading/loading.component';
import { ContainerLoadingComponent } from '../../components/container-loading/container-loading.component';
import { PreviewWebModalComponent } from '../../modals/preview-web-modal/preview-web-modal.component';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { PreviewPaperModalComponent } from '../../modals/preview-paper-modal/preview-paper-modal.component';
import { PaymentModalComponent } from '../../modals/payment-modal/payment-modal.component';
import { BillingDataEmptyModalComponent } from '../../modals/billing-data-empty-modal/billing-data-empty-modal.component';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from '../../../environments/environment';
import { PaymentOxxoModalComponent } from '../../modals/payment-oxxo-modal/payment-oxxo-modal.component';
import { PaymentCardModalComponent } from '../../modals/payment-card-modal/payment-card-modal.component';
import { DeleteAdvertisementComponent } from '../../modals/delete-advertisement/delete-advertisement.component';
import { UserActionsComponent } from '../../popover/user-actions/user-actions.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImageCropperModalComponent } from 'src/app/modals/image-cropper-modal/image-cropper-modal.component';
import { WarningPaymentModalComponent } from 'src/app/modals/warning-payment-modal/warning-payment-modal.component';
import { AlertContactEmptyModalComponent } from '../../modals/alert-contact-empty-modal/alert-contact-empty-modal.component';

@NgModule({
    declarations: [
        ButtonComponent,
        SelectComponent,
        InputComponent,
        CalendarComponent,
        SearchModalComponent,
        RegisterModalComponent,
        TermsConditionsModalComponent,
        PrivacyNoticeModalComponent,
        LoginModalComponent,
        ForgotPasswordModalComponent,
        SuccessModalComponent,
        PreviewWebModalComponent,
        PreviewPaperModalComponent,
        BillingDataEmptyModalComponent,
        PaymentModalComponent,
        PaymentOxxoModalComponent,
        PaymentCardModalComponent,
        SafeUrlPipe,
        DefaultImageDirective,
        HeaderComponent,
        FooterComponent,
        LoadingComponent,
        ContainerLoadingComponent,
        DeleteAdvertisementComponent,
        UserActionsComponent,
        ImageCropperModalComponent,
        WarningPaymentModalComponent,
        AlertContactEmptyModalComponent,
    ],
    imports: [
        CommonModule,
        NgbDropdownModule,
        FormsModule,
        ReactiveFormsModule,
        NgbDatepickerModule,
        IonicModule,
        RouterModule,
        SwiperModule,
        ImageCropperModule,
        NgxStripeModule.forRoot(environment.stripeKeyInit),
    ],
    providers: [TitleCasePipe],
    exports: [
        ButtonComponent,
        SelectComponent,
        InputComponent,
        CalendarComponent,
        SearchModalComponent,
        RegisterModalComponent,
        TermsConditionsModalComponent,
        PrivacyNoticeModalComponent,
        LoginModalComponent,
        ForgotPasswordModalComponent,
        SuccessModalComponent,
        PreviewWebModalComponent,
        PreviewPaperModalComponent,
        BillingDataEmptyModalComponent,
        PaymentModalComponent,
        PaymentOxxoModalComponent,
        PaymentCardModalComponent,
        SafeUrlPipe,
        DefaultImageDirective,
        HeaderComponent,
        FooterComponent,
        LoadingComponent,
        ContainerLoadingComponent,
        DeleteAdvertisementComponent,
        ImageCropperModalComponent,
        WarningPaymentModalComponent,
        AlertContactEmptyModalComponent,
    ],
})
export class CommonsModule {}
