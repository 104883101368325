import { Component } from '@angular/core';
import { MenuController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppService } from './services/app.service';
import { Router } from '@angular/router';
import { ModalService } from './services/modal.service';
import { ToolsService } from './services/tools.service';
import { CategoryService } from './services/category.service';
import { AuthenticationService } from './services/authentication.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent {
    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        public appSrv: AppService,
        public authSrv: AuthenticationService,
        private modalSrv: ModalService,
        private toolsSrv: ToolsService,
        public router: Router,
        public menuCtrl: MenuController,
        public categorySrv: CategoryService,
    ) {
        this.initializeApp();
    }

    initializeApp(): void {
        this.platform.ready().then(() => {
            this.statusBar.styleDefault();
            this.splashScreen.hide();
        });
    }

    public async openModalRegister(): Promise<void> {
        this.menuCtrl.close().then();
        this.modalSrv.openModalRegister().then();
    }

    public async openModalLogin(): Promise<void> {
        this.menuCtrl.close().then();
        this.modalSrv.openModalLogin().then();
    }

    public goProfile(): void {
        this.menuCtrl.close().then();
        this.toolsSrv.goRouteProfile();
    }
}
