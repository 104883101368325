import { APP_INITIALIZER, NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CommonsModule } from './modules/commons/commons.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { AuthenticationInterceptorService } from './interceptors/authentication-interceptor.service';
import { Camera } from '@ionic-native/camera/ngx';
import {
    AuthenticationService,
    factoryUserSession,
} from './services/authentication.service';
import { AppService } from './services/app.service';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import localeEsExtra from '@angular/common/locales/extra/es';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { NgxHotjarModule, NgxHotjarRouterModule } from 'ngx-hotjar';
registerLocaleData(localeEs, 'es', localeEsExtra);
@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        CommonsModule,
        AppRoutingModule,
        HttpClientModule,
        NgbModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireAnalyticsModule,
        NgxHotjarModule.forRoot(environment.hotjarId),
        NgxHotjarRouterModule,
    ],
    providers: [
        { provide: LOCALE_ID, useValue: 'es-MX' },
        StatusBar,
        SplashScreen,
        Camera,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        SocialSharing,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthenticationInterceptorService,
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: factoryUserSession,
            multi: true,
            deps: [AuthenticationService, AppService],
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
