import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-alert-contact-empty-modal',
    templateUrl: './alert-contact-empty-modal.component.html',
    styleUrls: ['./alert-contact-empty-modal.component.scss'],
})
export class AlertContactEmptyModalComponent {
    @Input() buttonFunction: any;
    constructor(private modalCtrl: ModalController) {}

    closeModal(): Promise<void> {
        return this.modalCtrl.dismiss().then();
    }
}
