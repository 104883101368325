import { Component, OnInit } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { AuthenticationService } from '../../services/authentication.service';
import { TermsConditionsModalComponent } from '../terms-conditions-modal/terms-conditions-modal.component';
import { PrivacyNoticeModalComponent } from '../privacy-notice-modal/privacy-notice-modal.component';
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    Validators,
} from '@angular/forms';
import { AppService } from '../../services/app.service';
import { ErrorString, Strings } from '../../classes/messages';

@Component({
    selector: 'app-register-modal',
    templateUrl: './register-modal.component.html',
    styleUrls: ['./register-modal.component.scss'],
})
export class RegisterModalComponent implements OnInit {
    formSignin: FormGroup;
    showFormRegister = true;

    constructor(
        private appSrv: AppService,
        private modalCtrl: ModalController,
        private authenticationSrv: AuthenticationService,
        private fb: FormBuilder,
    ) {}

    ngOnInit(): void {
        this.formSignin = this.fb.group(
            {
                email: [
                    '',
                    [
                        Validators.required,
                        Validators.email,
                        Validators.pattern(
                            '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$',
                        ),
                    ],
                ],
                password: ['', [Validators.required, Validators.minLength(6)]],
                confirmPassword: [
                    '',
                    [Validators.required, Validators.minLength(6)],
                ],
            },
            { validator: this.matchPassword },
        );
    }

    private matchPassword(abstractControl: AbstractControl): null {
        const errorEmailPattern = abstractControl.get('email').errors;
        if (errorEmailPattern && errorEmailPattern.pattern) {
            abstractControl.get('email').setErrors({
                email: true,
            });
        }

        const password = abstractControl.get('password').value;
        const confirmPassword = abstractControl.get('confirmPassword').value;
        if (password !== confirmPassword) {
            abstractControl.get('confirmPassword').setErrors({
                ...abstractControl.get('confirmPassword').errors,
                matchpassword: true,
            });
        } else {
            return null;
        }
    }

    public async signInWithGoogle(): Promise<void> {
        try {
            await this.authenticationSrv.signInWithGoogle();
            this.appSrv.showToastDefault(Strings.welcome).then();
            this.closeModal();
        } catch (e) {
            console.error(e);
            this.appSrv.showToastDanger(ErrorString.unknownError).then();
        }
    }

    public async signUpWithEmailAndPassword(): Promise<void> {
        try {
            await this.appSrv.showLoading();
            const { email, password } = this.formSignin.value;
            const userCredential = await this.authenticationSrv.signUpWithEmailAndPassword(
                email,
                password,
            );
            userCredential.user.sendEmailVerification().then();
            await this.appSrv.dismissLoading();
            this.showFormRegister = false;
        } catch (e) {
            await this.appSrv.dismissLoading();
            console.error(e);

            let message = ErrorString.unknownError;
            if (e.code === 'auth/email-already-in-use') {
                message = ErrorString.emailAlreadyInUse;
            }

            this.appSrv.showToastDanger(message).then();
        }
    }

    public async openModalTermsConditions(): Promise<void> {
        const modal = await this.modalCtrl.create({
            component: TermsConditionsModalComponent,
            cssClass: 'app-modal tab-modal',
        });
        return await modal.present();
    }

    public async openModalPrivacyNotice(): Promise<void> {
        const modal = await this.modalCtrl.create({
            component: PrivacyNoticeModalComponent,
            cssClass: 'app-modal tab-modal',
        });
        return await modal.present();
    }

    public closeModal(): void {
        this.modalCtrl.dismiss({
            dismissed: true,
        });
    }
}
