import { Component, Input } from '@angular/core';
import { Advertisement } from '../../models/advertisement';
import { SwiperOptions } from 'swiper';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-preview',
    templateUrl: './preview-web-modal.component.html',
    styleUrls: ['./preview-web-modal.component.scss'],
})
export class PreviewWebModalComponent {
    @Input() advertisement: Advertisement;
    configSwipeGalleryTop: SwiperOptions = {
        a11y: { enabled: true },
        direction: 'horizontal',
        observer: true,
        observeParents: true,
        spaceBetween: 11,
        slidesPerView: 1,
        navigation: {
            prevEl: '.swiper-back',
            nextEl: '.swiper-next',
        },
    };

    constructor(private modalCtrl: ModalController) {}

    public closeModal(): void {
        this.modalCtrl
            .dismiss({
                dismissed: true,
            })
            .then();
    }
}
