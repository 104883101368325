export class Context {
    uuid: string;
    newspaperSkin: NewsPaperSkin;
    zipCodes: Array<string>;
    paymentMethods: Array<PaymentMethod>;
    newspaperRss: string;
    socialNetworks: Array<SocialNetwork>;
    contactInfo: ContactInfo;
    uri: string;
    contextId: string;
    isPaper: boolean;
    isWeb: boolean;
    isSelling: boolean;

}

export class NewsPaperSkin {
    displayName: string;
    primaryColor: string;
    secondaryColor: string;
    logo: string;
}

export class PaymentMethod {
    uuid: string;
    name: string;
    url: string;
}

export class SocialNetwork {
    name: string;
    icon: string;
    url: string;
}

export class ContactInfo {
    contactNumber: string;
    extNumber: string;
    contactEmail: string;
}

export class ContextPreview {
    displayName: string;
    uri: string;
    uuid: string;
}
