import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import {
    Advertisement,
    AdvertisementPublishedStatus,
    AdvertisementUserStatus,
} from '../../models/advertisement';
import { AdvertisementService } from '../../services/advertisement.service';
import { Page } from '../../classes/page';
import { AppService } from '../../services/app.service';
import { ErrorManagerService } from '../../services/error-manager.service';

@Component({
    selector: 'app-delete-advertisement',
    templateUrl: './delete-advertisement.component.html',
    styleUrls: ['./delete-advertisement.component.scss'],
})
export class DeleteAdvertisementComponent extends Page {
    @Input() advertisement: Advertisement;

    constructor(
        private modalCtrl: ModalController,
        private advertisementSrv: AdvertisementService,
        public appSrv: AppService,
        private errorManagerSrv: ErrorManagerService,
    ) {
        super(appSrv);
    }

    public async closeModal(): Promise<void> {
        await this.modalCtrl.dismiss({
            dismissed: true,
            deleted: false,
        });
    }

    async deleteAdvertisement(): Promise<any> {
        await this.errorManagerSrv.errorManager(async () => {
            this.advertisement.status.user =
                this.advertisement.status.published ===
                AdvertisementPublishedStatus.published
                    ? AdvertisementUserStatus.paused
                    : AdvertisementUserStatus.cancelled;

            await this.advertisementSrv
                .saveAdvertisement(this.advertisement)
                .toPromise();
        });

        await this.modalCtrl.dismiss({
            dismissed: true,
            deleted: true,
        });
    }
}
