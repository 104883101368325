import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';
import { User } from '../models/user';
import { AppService } from '../services/app.service';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate {
    private readonly pathPrivateList: Array<string> = [
        '/account',
        '/create-advertisement',
        '/edit-advertisement',
        '/my-advertisements',
        '/profile',
        '/payment-success',
    ];

    constructor(
        private authSrv: AuthenticationService,
        private $router: Router,
        private appSrv: AppService,
    ) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean> {
        return this.authSrv.user.pipe(
            map((user: User) => {
                if (
                    !this.appSrv.contextLoader.isWeb &&
                    this.appSrv.contextLoader.isPaper
                ) {
                    if (this.isPrivatePath(state.url)) {
                        if (user) {
                            return true;
                        } else {
                            this.redirectToLogin();
                            return false;
                        }
                    } else {
                        if (user) {
                            const path = state.url.substr(0, 20);
                            if (path === '/detail-announcement') {
                                return true;
                            } else {
                                this.redirectToProfile();
                                return false;
                            }
                        } else {
                            if (state.url.startsWith('/login')) {
                                return true;
                            } else {
                                this.redirectToLogin();
                                return false;
                            }
                        }
                    }
                } else {
                    if (this.isPrivatePath(state.url)) {
                        if (user && user.emailVerified) {
                            return true;
                        } else {
                            this.redirectToHome();
                            return false;
                        }
                    } else {
                        return true;
                    }
                }
            }),
        );
    }

    private isPrivatePath(path: string): boolean {
        return !!this.pathPrivateList.find((p) => {
            return path.startsWith(p);
        });
    }

    private redirectToHome(): void {
        this.$router.navigate(['/home']).then();
    }

    private redirectToProfile(): void {
        this.$router.navigate(['/profile']).then();
    }

    private redirectToLogin(): void {
        this.$router.navigate(['/login']).then();
    }
}
