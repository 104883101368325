import { Component } from '@angular/core';
import { AppService } from '../../services/app.service';
import { ModalService } from '../../services/modal.service';
import { ToolsService } from '../../services/tools.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
    constructor(
        public appSrv: AppService,
        public modalSrv: ModalService,
        public toolsSrv: ToolsService,
    ) {}
}
