import { Builder } from 'builder-pattern';
import { Strings } from '../classes/messages';

export class ErrorResponse {
    errors: Array<ErrorDetailResponse>;

    public getFirstError(): ErrorDetailResponse {
        return this.errors && this.errors.length > 0
            ? this.errors[0]
            : Builder(ErrorDetailResponse).build();
    }

    get error(): ErrorDetailResponse {
        if (this.errors && this.errors.length) {
            return this.errors[0];
        } else {
            return Builder(ErrorDetailResponse)
                .type(Strings.typeErrorUnknown)
                .message(Strings.unknownError)
                .build();
        }
    }
}

export class ErrorDetailResponse {
    message: string;
    type: string;
}
