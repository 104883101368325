export const environment = {
    production: true,
    api: {
        context: 'https://ctx-http-3zkvc3uzzq-uc.a.run.app',
        advertisement: 'https://classified-http-3zkvc3uzzq-uc.a.run.app',
        package: 'https://package-http-3zkvc3uzzq-uc.a.run.app',
        user: 'https://user-http-3zkvc3uzzq-uc.a.run.app',
        payment: 'https://payment-http-3zkvc3uzzq-uc.a.run.app',
    },
    stripeKeyInit: 'null',
    stripeKey: {
        '2700':
            'pk_test_51NVJwFCRkIEIlhbUtYUolHf5HOhr6e0vp92o7sNjqNZJIy5BgmkpCpOtNekCDiXPhByKGu7kvtvYgvjQ5nhsr3zp00bhcsa6S8',
        '0000':
            'pk_test_51HaO8IDvNFdl3iuVdE5NsGCUzj3L00Q6Z3EdMaj9fT76cqJDDEQAM4O0XYAQF7U7Fjm3CwoEx2E4szt6WC3z4Atv005TJuFkzw',
    },
    paypal: {
        clientId: 'notnull',
        env: 'sandbox',
    },
    keyGoogleMaps: '',
    firebaseConfig: {
        apiKey: 'AIzaSyBLYsxivhkmogJgm0Dbn-jTlHPh6c-SOiw',
        authDomain: 'oem-clasificados-desarrollo.firebaseapp.com',
        databaseURL: 'https://oem-clasificados-desarrollo.firebaseio.com',
        projectId: 'oem-clasificados-desarrollo',
        storageBucket: 'oem-clasificados-desarrollo.appspot.com',
        messagingSenderId: '517637134192',
        appId: '1:517637134192:web:b80995ef6ec4105f8521b5',
    },
    hotjarId: '2398260',
};
