import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    Validators,
} from '@angular/forms';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
    selector: 'app-forgot-password-modal',
    templateUrl: './forgot-password-modal.component.html',
    styleUrls: ['./forgot-password-modal.component.scss'],
})
export class ForgotPasswordModalComponent implements OnInit {
    form: FormGroup;
    showFormEmail = true;

    constructor(
        private modalCtrl: ModalController,
        private fb: FormBuilder,
        private authSrv: AuthenticationService,
    ) {}

    ngOnInit(): void {
        this.form = this.fb.group(
            {
                email: [
                    '',
                    [
                        Validators.email,
                        Validators.required,
                        Validators.pattern(
                            '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$',
                        ),
                    ],
                ],
            },
            { validators: this.validatePatternEmail },
        );
    }

    private validatePatternEmail(abstractControl: AbstractControl): void {
        const errorEmailPattern = abstractControl.get('email').errors;
        if (errorEmailPattern && errorEmailPattern.pattern) {
            abstractControl.get('email').setErrors({
                email: true,
            });
        }
    }

    public async forgotPassword(): Promise<void> {
        const email: string = this.form.get('email').value;
        await this.authSrv.sendPasswordResetEmail(email);
        this.showFormEmail = false;
    }

    close(): void {
        this.modalCtrl.dismiss({
            dismissed: true,
        });
    }
}
