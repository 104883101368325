import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-privacy-notice-modal',
    templateUrl: './privacy-notice-modal.component.html',
    styleUrls: ['./privacy-notice-modal.component.scss'],
})
export class PrivacyNoticeModalComponent implements OnInit {
    constructor(private modalCtrl: ModalController) {}

    ngOnInit(): void {
        //    no body
    }

    close(): void {
        this.modalCtrl.dismiss({
            dismissed: true,
        });
    }
}
