import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-container-loading',
    templateUrl: './container-loading.component.html',
    styleUrls: ['./container-loading.component.scss'],
})
export class ContainerLoadingComponent {
    @Input() showContent: boolean;
    @Input() space = true;
}
