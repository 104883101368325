import { Context } from '../models/context';
import { AppService } from '../services/app.service';
import { filter, first } from 'rxjs/operators';
import { ErrorResponse } from '../models/error-response';

export class Page {
    context: Context;

    constructor(public appSrv: AppService) {}

    public async loadContext(): Promise<void> {
        this.context = await this.appSrv.context
            .pipe(
                filter((context) => !!context),
                first(),
            )
            .toPromise();
    }
}
