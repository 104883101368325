import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AuthenticationGuard } from "./guards/authentication-guard.service";

const routes: Routes = [
    {
        path: "",
        redirectTo: "home",
        pathMatch: "full"
    },
    {
        path: "home",
        canActivate: [AuthenticationGuard],
        loadChildren: () =>
            import("./pages/home/home.module").then((m) => m.HomePageModule)
    },
    {
        path: "profile",
        canActivate: [AuthenticationGuard],
        loadChildren: () =>
            import("./pages/profile/profile.module").then(
                (m) => m.ProfilePageModule
            )
    },
    {
        path: "account",
        canActivate: [AuthenticationGuard],
        loadChildren: () =>
            import("./pages/account/account.module").then(
                (m) => m.AccountPageModule
            )
    },
    {
        path: "edit-advertisement/:id",
        canActivate: [AuthenticationGuard],
        loadChildren: () =>
            import(
                "./pages/create-advertisement/create-advertisement.module"
                ).then((m) => m.CreateAdvertisementPageModule)
    },
    {
        path: "create-advertisement",
        canActivate: [AuthenticationGuard],
        loadChildren: () =>
            import(
                "./pages/create-advertisement/create-advertisement.module"
                ).then((m) => m.CreateAdvertisementPageModule)
    },
    {
        path: "restore-password",
        canActivate: [AuthenticationGuard],
        loadChildren: () =>
            import("./pages/restore-password/restore-password.module").then(
                (m) => m.RestorePasswordPageModule
            )
    },
    {
        path: "detail-announcement/:id",
        canActivate: [AuthenticationGuard],
        loadChildren: () =>
            import(
                "./pages/detail-announcement/detail-announcement.module"
                ).then((m) => m.DetailAnnouncementPageModule)
    },
    {
        path: "detail-announcement-owner/:id",
        canActivate: [AuthenticationGuard],
        loadChildren: () =>
            import(
                "./pages/detail-announcement-owner/detail-announcement-owner.module"
                ).then((m) => m.DetailAnnouncementPageModule)
    },
    {
        path: "search-by-category/:id",
        canActivate: [AuthenticationGuard],
        loadChildren: () =>
            import("./pages/search-by-category/search-by-category.module").then(
                (m) => m.SearchByCategoryPageModule
            )
    },
    {
        path: "search-by-filter",
        canActivate: [AuthenticationGuard],
        loadChildren: () =>
            import("./pages/search-by-filter/search-by-filter.module").then(
                (m) => m.SearchByFilterPageModule
            )
    },
    {
        path: "payment-success",
        canActivate: [AuthenticationGuard],
        loadChildren: () =>
            import("./pages/payment-success/payment-success.module").then(
                (m) => m.PaymentSuccessPageModule
            )
    },
    {
        path: "payment-error",
        canActivate: [AuthenticationGuard],
        loadChildren: () =>
            import("./pages/payment-error/payment-error.module").then(
                (m) => m.PaymentErrorPageModule
            )
    },
    {
        path: "my-advertisements",
        canActivate: [AuthenticationGuard],
        loadChildren: () =>
            import("./pages/my-advertisements/my-advertisements.module").then(
                (m) => m.MyAdvertisementsPageModule
            )
    },
    {
        path: "login",
        canActivate: [AuthenticationGuard],
        loadChildren: () =>
            import("./pages/login/login.module").then((m) => m.LoginPageModule)
    },
    {
        path: "destacados",
        loadChildren: () =>
            import("./pages/highlights/highlights.module").then(
                (m) => m.HighlightsPageModule
            )
    },
    {
        path: 'aviso-privacidad',
        loadChildren: () => import('./pages/aviso-privacidad/aviso-privacidad.module').then( m => m.AvisoPrivacidadPageModule)
    },
    {
        path: 'terminos-y-condiciones',
        loadChildren: () => import('./pages/terminos-y-condiciones/terminos-y-condiciones.module').then( m => m.TerminosYCondicionesPageModule)
    },
    {
        path: "**",
        redirectTo: "home"
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
