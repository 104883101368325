import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Advertisement } from '../../models/advertisement';
import { StripeCardComponent, StripeService } from 'ngx-stripe';
import { environment } from '../../../environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
    PaymentIntent,
    ProcessorEntity,
    ProcessorMethod,
} from '../../models/payment';
import {
    StripeCardElementChangeEvent,
    StripeCardElementOptions,
    StripeElementsOptions,
} from '@stripe/stripe-js';
import { AppService } from '../../services/app.service';
import { ModalController } from '@ionic/angular';
import { ModalService } from '../../services/modal.service';
import { PaymentService } from '../../services/payment.service';
import { Strings } from '../../classes/messages';
import { ErrorResponse } from '../../models/error-response';
import { ErrorManagerService } from '../../services/error-manager.service';
import { Builder } from 'builder-pattern';
import { ToolsService } from '../../services/tools.service';
import { first } from 'rxjs/operators';

@Component({
    selector: 'app-payment-card-modal',
    templateUrl: './payment-card-modal.component.html',
    styleUrls: ['./payment-card-modal.component.scss'],
})
export class PaymentCardModalComponent implements OnInit {
    @Input() advertisement: Advertisement;
    @ViewChild(StripeCardComponent) cardElement: StripeCardComponent;
    stripeFormCard: FormGroup;
    stripePaymentIntentCard: PaymentIntent;
    stripeCardElementEvent: StripeCardElementChangeEvent;
    stripeCardOptions: StripeCardElementOptions = {
        style: {
            base: {
                iconColor: '#C50406',
                color: '#31325F',
                fontWeight: '300',
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSize: '18px',
                '::placeholder': {
                    color: '#CFD7E0',
                },
            },
        },
    };
    stripeElementsOptions: StripeElementsOptions = {
        locale: 'es',
    };

    constructor(
        private appSrv: AppService,
        private modalCtrl: ModalController,
        private modalSrv: ModalService,
        private fb: FormBuilder,
        private stripeSrv: StripeService,
        private paymentSrv: PaymentService,
        private toolsSrv: ToolsService,
    ) {}

    async ngOnInit(): Promise<void> {
        var key = null;
        if (environment.stripeKey[this.advertisement.contextId]) {
            key = environment.stripeKey[this.advertisement.contextId];
        } else {
            key = environment.stripeKey['0000'];
        }
        this.stripeSrv.changeKey(key);
        this.setupForm();
        this.loadPaymentIntentCard().then();
    }

    private setupForm() {
        this.stripeFormCard = this.fb.group({
            name: ['', [Validators.required]],
        });
    }

    private async loadPaymentIntentCard() {
        const configPayment = Builder(PaymentIntent)
            .advertisementUUID(this.advertisement.uuid)
            .amount(this.advertisement.details.price.priceWithTax)
            .processorEntity(ProcessorEntity.stripe)
            .processorMethod(ProcessorMethod.default)
            .build();
        this.stripePaymentIntentCard = await this.paymentSrv
            .getPaymentIntent(configPayment, this.advertisement.contextId)
            .toPromise();
    }

    public async payWithCard(): Promise<void> {
        try {
            await this.appSrv.showLoading();
            const name = this.stripeFormCard.get('name').value;
            const result = await this.stripeSrv
                .confirmCardPayment(this.stripePaymentIntentCard.clientSecret, {
                    payment_method: {
                        card: this.cardElement.element,
                        billing_details: {
                            name,
                        },
                    },
                })
                .toPromise();

            if (result.error) {
                this.appSrv.showToastDanger(result.error.message).then();
            } else {
                const paymentIntent = result.paymentIntent;
                this.stripePaymentIntentCard.stripePaymentMethodId =
                    paymentIntent.payment_method;
                await this.paymentSrv
                    .setPaymentMethod(this.stripePaymentIntentCard, this.advertisement.contextId )
                    .pipe(first())
                    .toPromise();
                this.appSrv.showToastDefault(Strings.paymentSuccess).then();
                this.toolsSrv.goRoutePaymentSuccess();
                this.closeModal();
            }
            this.appSrv.dismissLoading().then();
        } catch (e) {
            const error = (e as ErrorResponse).getFirstError();
            this.appSrv
                .showToastDanger(
                    ErrorManagerService.getMessageErrorByTypeErrorResponse(
                        error,
                    ),
                )
                .then();
        }
    }

    public updateCard(event: StripeCardElementChangeEvent): void {
        this.stripeCardElementEvent = event;
    }

    public closeModal(): void {
        this.modalCtrl
            .dismiss({
                dismissed: true,
            })
            .then();
    }
}
