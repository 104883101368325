import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AuthenticationService } from '../../services/authentication.service';
import { ForgotPasswordModalComponent } from '../forgot-password-modal/forgot-password-modal.component';
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    Validators,
} from '@angular/forms';
import { AppService } from '../../services/app.service';
import { ErrorString, Strings } from '../../classes/messages';
import { RegisterModalComponent } from '../register-modal/register-modal.component';

@Component({
    selector: 'app-login-modal',
    templateUrl: './login-modal.component.html',
    styleUrls: ['./login-modal.component.scss'],
})
export class LoginModalComponent implements OnInit {
    formSignin: FormGroup;

    constructor(
        private appSrv: AppService,
        private modalCtrl: ModalController,
        private authenticationSrv: AuthenticationService,
        private fb: FormBuilder,
    ) {}

    ngOnInit(): void {
        this.formSignin = this.fb.group(
            {
                email: [
                    '',
                    [
                        Validators.required,
                        Validators.email,
                        Validators.pattern(
                            '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$',
                        ),
                    ],
                ],
                password: ['', [Validators.required, Validators.minLength(6)]],
            },
            { validators: this.validatePatternEmail },
        );
    }

    private validatePatternEmail(abstractControl: AbstractControl): void {
        const errorEmailPattern = abstractControl.get('email').errors;
        if (errorEmailPattern && errorEmailPattern.pattern) {
            abstractControl.get('email').setErrors({
                email: true,
            });
        }
    }

    public async signInWithGoogle(): Promise<void> {
        try {
            await this.authenticationSrv.signInWithGoogle();
            this.appSrv.showToastDefault(Strings.welcome).then();
            this.closeModal();
        } catch (e) {
            console.error(e);
            this.appSrv.showToastDanger(ErrorString.unknownError).then();
        }
    }

    public async signInWithEmailAndPassword(): Promise<void> {
        try {
            const { email, password } = this.formSignin.value;
            await this.authenticationSrv.signInWithEmailAndPassword(
                email,
                password,
            );
            this.appSrv.showToastDefault(Strings.welcome).then();
            this.closeModal();
        } catch (e) {
            console.log(e);

            let message = ErrorString.unknownError;
            if (e.code === 'auth/user-not-found') {
                message = ErrorString.userNotFound;
            } else if (e.code === 'auth/wrong-password') {
                message = Strings.passwordInvalid;
            }

            this.appSrv.showToastDanger(message).then();
        }
    }

    public async openModalForgotPassword(): Promise<void> {
        this.closeModal();
        const modal = await this.modalCtrl.create({
            component: ForgotPasswordModalComponent,
            cssClass: 'app-modal tab-modal',
        });
        return await modal.present();
    }

    public async openModalRegister(): Promise<void> {
        this.closeModal();
        const modal = await this.modalCtrl.create({
            component: RegisterModalComponent,
            cssClass: 'app-modal tab-modal',
        });
        return await modal.present();
    }

    public closeModal(): void {
        this.modalCtrl
            .dismiss({
                dismissed: true,
            })
            .then();
    }
}
