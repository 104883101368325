import { Injectable } from '@angular/core';
import { ErrorDetailResponse, ErrorResponse } from '../models/error-response';
import { ErrorFormString, Strings } from '../classes/messages';
import { AppService } from './app.service';
import { ErrorManagerOptions } from '../interfaces/error-manager-opts';

@Injectable({
    providedIn: 'root',
})
export class ErrorManagerService {
    constructor(private appSrv: AppService) {}

    public static getMessageErrorByTypeErrorResponse(
        error: ErrorDetailResponse,
    ): string {
        if (error.type === Strings.typeErrorForm) {
            return Strings.formError;
        } else if (error.type === Strings.typeErrorUnauthorized) {
            return Strings.unauthorizedError;
        } else if (error.type === Strings.typeErrorNotFound) {
            return Strings.notFoundError;
        } else if (error.type === Strings.typeErrorInvalidEmail) {
            return ErrorFormString.email;
        } else {
            return Strings.unknownError;
        }
    }

    public async errorManager(
        cb: any,
        options?: ErrorManagerOptions,
    ): Promise<void> {
        if (cb) {
            try {
                await this.appSrv.showLoading();
                await cb();
                await this.appSrv.dismissLoading();
            } catch (e) {
                console.log(e);
                await this.appSrv.dismissLoading();
                const error: ErrorResponse = e as ErrorResponse;
                if (!options?.hideErrorAlert) {
                    await this.appSrv.showToastDanger(
                        options?.messageError
                            ? options.messageError
                            : error.error.message,
                    );
                }
            }
        }
    }
}
