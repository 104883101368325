import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormBuilder } from '@angular/forms';
import { CategoryService } from '../../services/category.service';
import { TitleCasePipe } from '@angular/common';
import { ViewSearch } from '../../classes/view-search';
import { AppService } from '../../services/app.service';
import { ToolsService } from '../../services/tools.service';
import { AdvertisementService } from '../../services/advertisement.service';

@Component({
    selector: 'app-search',
    templateUrl: './search-modal.component.html',
    styleUrls: ['./search-modal.component.scss'],
})
export class SearchModalComponent extends ViewSearch implements OnInit {
    constructor(
        public appSrv: AppService,
        private modalCtrl: ModalController,
        public categorySrv: CategoryService,
        public titleCasePipe: TitleCasePipe,
        public fb: FormBuilder,
        public toolsSrv: ToolsService,
        public advertisementSrv: AdvertisementService,
    ) {
        super(
            appSrv,
            categorySrv,
            titleCasePipe,
            fb,
            toolsSrv,
            advertisementSrv,
        );
    }

    ngOnInit(): void {
        // no body
    }

    closeModal(): void {
        this.modalCtrl.dismiss({
            dismissed: true,
        });
    }

    goSearchFilterPage(): void {
        this.goSearchFilterPageByForm();
        this.closeModal();
    }
}
