import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ToolsService } from '../../services/tools.service';

@Component({
    selector: 'app-success-modal',
    templateUrl: './success-modal.component.html',
    styleUrls: ['./success-modal.component.scss'],
})
export class SuccessModalComponent {
    constructor(
        private modalCtrl: ModalController,
        private toolsSrv: ToolsService,
    ) {}

    public closeModal(): void {
        this.modalCtrl.dismiss({
            dismissed: true,
        });
    }

    public goHome(): void {
        this.closeModal();
        this.toolsSrv.goRouteHome();
    }
}
